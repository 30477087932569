
export default class Aerodrome {

  constructor(id, name, code, geo, order, email) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.geo = geo || { x_: 46.5, N_: 28.5 };
    this.order = order || 500;
    this.email = email || '';
  }



}