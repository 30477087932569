<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-row align="center" class="pa-0">
          <v-col cols="auto">
          <v-icon color="white" class="mr-3">{{ icons.mdiCreditCardOutline }}</v-icon>
            Payment Succes
          </v-col>
          <v-spacer class="d-none d-sm-flex" />          
          <v-col class="d-none d-sm-flex">
          
          </v-col>
   </v-row>
 </v-app-bar> 
<v-main class="pa-0">

<v-container fluid class="pa-5">
   <v-layout wrap>
       <v-flex class="">
           <v-card class="d-flex justify-center pa-0" outlined>
               <v-card-text class="text-center">
                <v-icon color="teal" x-large>{{ icons.mdiCreditCardCheck }}</v-icon>
                <v-spacer class="d-none d-sm-flex" />
                <v-simple-table dense class="pa-0">
                    <tbody>
                        <tr>
                            <td class="text-center" width="50%">Payment ID</td>
                            <td class="text-center" width="50%"><b>{{ currentpayment.paymentid }}</b></td>
                        </tr>
                        <tr>
                            <td class="text-center" width="50%">Phone number</td>
                            <td class="text-center" width="50%"><b>{{ currentpayment.phonenumber }}</b></td>
                        </tr>
                        <tr>
                            <td class="text-center" width="50%">Payment date</td>
                            <td class="text-center" width="50%"><b>{{ currentpayment.date }}</b></td>
                        </tr>
                        <tr>
                            <td class="text-center" width="50%">Amount</td>
                            <td class="text-center" width="50%"><b>{{ currentpayment.amount }}</b></td>
                        </tr>
                        <tr>
                            <td class="text-center" width="50%">Status</td>
                            <td class="text-center" width="50%"><b>{{ currentpayment.status }}</b></td>
                        </tr>
                    </tbody>
                </v-simple-table>
                
                <v-btn color="teal" class="white--text ma-3" @click="paymentsbutton">
                    MAKE ANOTHER PAYMENT
                    <v-icon right dark> {{ icons.mdiCreditCardOutline }} </v-icon>
                </v-btn>
               </v-card-text>               
           </v-card>
       </v-flex>
   </v-layout>
</v-container>

  <v-container fluid class="pa-5">
   <v-row class="pa-0 green darken-4" align="center">
       <v-col class="pa-0">
        <v-data-table
          dense
          disable-pagination	
          disable-sort
          :headers="headerspayments"
          :items="paymentlog"
          item-key="name"
          hide-default-footer
          class="rounded-0"
        ></v-data-table>
       </v-col>
    </v-row>
  </v-container>

</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>
/* eslint-disable */
import Menu from '../components/menu.vue'
import { mdiCreditCardOutline, mdiCreditCardCheck } from '@mdi/js'
import moment from 'moment'
import { auth, firestore } from '../firebase'
import { functions } from "../firebase";

export default {
    name: 'SuccessPayment',

    components: {
        Menu
    },

    data: () => ({ 
        icons: {
          mdiCreditCardOutline,
          mdiCreditCardCheck
        },
        activeBtn: 'account',
        valid: true,
        selectedMonth: '',
        headerspayments: [
          { text: 'Date',  value: 'date', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Service', value: 'service', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Amount', value:'amount', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Status', value:'status', sortable:false, class: 'white--text pink darken-2 text-caption' }
        ],
        PaymentId: ''
    }),

    computed: {
        
        account() {
          try {
            const self = this;
            return self.$store.getters.getMyAccount;
          }
          catch {
            return false;
          }
        },
        mypayments() {
            try {
            const self = this;
            return self.$store.getters.getMyPayments;
          }
          catch {
            return false;
          }
        },
        paymentlog: {
            get() {
                const self = this;
                const log = [];
                self.mypayments.forEach(function (item, index) {
                    var statustext = '';
                    switch(item.Status) 
                    { 
                      case 1: statustext = "Registered"; break;
                      case 2: statustext = "In progress"; break;
                      case 3: statustext = "In progress"; break;
                      case 4: statustext = "Completed"; break;
                      case 100: statustext = "Paid"; break;
                      default: statustext = "Failed"; break;
                       
                    }
                    log.push({
                        paymentid: item.PaymentId,
                        date: moment(item.Registered).format('YYYY-MM-DD HH:mm'),
                        amount: item.Amount / 100 + ' LEI',
                        service: item.Services[0].Products[0].Name,
                        phonenumber: item.Customer.Code,
                        status: statustext
                    });
                });
                return log;
            },
            set (paymentlog) {
                return paymentlog;
            }
        },   
        
        currentpayment: {
            get() {
                try {
                    const self = this;
                    if (self.paymentlog[0]) {
                        return self.paymentlog.find(item => item.paymentid == self.$route.params.id);
                    } else {
                        return false;
                    }                    
                }
                catch {
                    return false;
                }
                  
            },
            set (currentpayment) {
                return currentpayment;
            }
             
        }
        
    },

    watch: {                
    },

    methods: {
      paymentsbutton() {
            this.$router.replace('/payments');
        },
        
    },
    created() {
        const self = this;

        new Promise(function(resolve) {
          if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
          resolve(1);
        }).then(function(result) { 
          if (!self.$store.state.mypayments[0]) self.$store.commit('updatePaymentsFromDb', auth.currentUser.phoneNumber)
          return result;
        });
    },

    mounted() {
        const self = this;
    }

}


</script>