<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-icon color="white" class="mr-3"> {{ icons.mdiEye }}</v-icon>
          Preview Flight Plan
 </v-app-bar> 
<v-main class="pa-0">
  <v-container fluid class="pa-4">
   <v-layout wrap>
       <v-flex class="">
       <v-form ref="form" v-model="valid" lazy-validation>
             <v-textarea
                v-model="rawflightplan"
                readonly
                label="Flight plan message"
                rows="11"
                background-color="grey lighten-4"
                no-resize
                flat
                dense
              ></v-textarea>

              <v-layout row child-flex justify-center align-center wrap pa-4>
                <v-btn :disabled="!valid" color="red" class="white--text" :to="{name:'NewFlightPlan'}" >
                    <v-icon left dark> {{ icons.mdiKeyboardBackspace }} </v-icon>
                    BACK                    
                </v-btn>
                <v-spacer />
                <v-btn :disabled="!valid" color="success" class="white--text" @click="savefplbutton">
                    SAVE
                    <v-icon right dark> {{ icons.mdiContentSave }} </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn :disabled="!valid" color="primary" class="white--text" @click="submitfplbutton">
                    SUBMIT
                    <v-icon right dark> {{ icons.mdiSend }} </v-icon>
                </v-btn>
              </v-layout>

        </v-form>        
       </v-flex>
   </v-layout>
  </v-container>
</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>
/* eslint-disable */

import Menu from '../components/menu.vue'
import { auth, firestore } from '../firebase'
import { mdiEye, mdiSend, mdiContentSave, mdiKeyboardBackspace } from '@mdi/js'
import mixin from '../mixin'
import Flightplan from '../domain/flightplan'
import Aircraft from '../domain/aircraft'
import Aerodrome from '../domain/aerodrome'
import Crew from '../domain/crew'
import Timetableitem from '../domain/timetableitem'
import moment from 'moment-timezone'
import { classToPlain } from 'class-transformer';



export default {
    name: 'PreviewFlightPlan',
    mixins: [mixin],
    components: {
        Menu
    },

    data: () => ({ 
        icons: {
            mdiEye,
            mdiSend,
            mdiContentSave, 
            mdiKeyboardBackspace
        },
        activeBtn: 'previewfpl',
        valid: true
    }),

    computed: {
        rawflightplan() {
            const self = this;
            return self.generatefplmessage(self.flightplan.fpl);
        },  

        flightplan() {
            try {
            const self = this;
            let autoID = Object.is(autoID, undefined) ? firestore.collection('flightplan').doc().id : autoID;            
            var aircraft = new Aircraft(self.$store.getters.getSelectedAircraft.id, 
                                    self.$store.getters.getSelectedAircraft.tailnumber, 
                                    self.$store.getters.getSelectedAircraft.type,
                                    self.$store.getters.getSelectedAircraft.speed,
                                    self.$store.getters.getSelectedAircraft.equipment,
                                    self.$store.getters.getSelectedAircraft.wake,
                                    self.$store.getters.getSelectedAircraft.owneremail
                                    );
            var departureAerodrome = new Aerodrome(self.$store.getters.getDepartureAerodrome.id,
                                    self.$store.getters.getDepartureAerodrome.name,
                                    self.$store.getters.getDepartureAerodrome.code,
                                    self.$store.getters.getDepartureAerodrome.geo,
                                    self.$store.getters.getDepartureAerodrome.order,
                                     self.$store.getters.getDepartureAerodrome.email,
                                    );                                
            var destinationAerodrome = new Aerodrome(self.$store.getters.getDestinationAerodrome.id,
                                    self.$store.getters.getDestinationAerodrome.name,
                                    self.$store.getters.getDestinationAerodrome.code,
                                    self.$store.getters.getDestinationAerodrome.geo,
                                    self.$store.getters.getDestinationAerodrome.order,
                                    self.$store.getters.getDepartureAerodrome.email,
                                    );                                    
            var departureDateTimeInitial = new Date(moment.utc(self.$store.state.flightplan.dateflight + ' ' + self.$store.state.flightplan.departureTime));
            var departureDateTime = new Date(moment.utc(self.$store.state.flightplan.dateflight + ' ' + self.$store.state.flightplan.departureTime));
            var durationMin = moment.duration(self.$store.state.flightplan.durationTime).asMinutes();
            var route = self.$store.state.flightplan.routeflight;
            var level = self.$store.state.flightplan.levelflight;
            var typeofflight = self.$store.state.flightplan.typeofflight;            
            var persons = self.$store.state.flightplan.personsonboard;            
            var crew = new Crew(self.$store.getters.getMyAccount.id,
                                    self.$store.getters.getMyAccount.firstname,
                                    self.$store.getters.getMyAccount.lastname,
                                    self.$store.getters.getMyAccount.phone,
                                    self.$store.getters.getMyAccount.email
                                    );
            var meta = {
                "createddatetime": new Date(),
                "submitteddatetime": '',
                "delayeddatetime": '',
                "closedatetime": '',
                "cancelleddatetime": '',
                "userid": auth.currentUser.uid,
                "showuntil": new Date(moment.utc(self.$store.state.flightplan.dateflight + ' ' + self.$store.state.flightplan.departureTime).tz("Europe/Chisinau").endOf('day') ),
                "status": ''
            };         
            var fpl = new Flightplan(autoID, aircraft, departureAerodrome, destinationAerodrome, departureDateTimeInitial, departureDateTime, durationMin, route, level, typeofflight, persons, crew, meta);
            var timetableitem = new Timetableitem(autoID, aircraft.tailnumber, aircraft.type, departureAerodrome.id, departureAerodrome.code, departureAerodrome.name, 
                                                    destinationAerodrome.id, destinationAerodrome.code, destinationAerodrome.name, departureDateTime, moment(departureDateTime).add(durationMin, 'm').toDate(), 
                                                    durationMin, crew.firstname + ' ' + crew.lastname, new Date(), auth.currentUser.uid, '');
           
            return {fpl: fpl, timetableitem: timetableitem};
            }
            catch {
                return {fpl: {}, timetableitem: {}}
            }
        }
    },

    methods: {
        savefplbutton() { 
            const self = this;  
            self.flightplan.fpl.meta.status = "created";
            self.flightplan.timetableitem.status = "created";
            var rflightplan = firestore.collection('flightplan').doc(self.flightplan.fpl.id)
            var rtimetableitem = firestore.collection('timetable').doc(self.flightplan.timetableitem.id)
            var batch = firestore.batch();
            batch.set(rflightplan, classToPlain(self.flightplan.fpl));
            batch.set(rtimetableitem, classToPlain(self.flightplan.timetableitem));
            batch.commit().then(function () {
                console.log("Flightplan successfully saved!");
                self.$store.commit('resetStateFlightplan');
                self.$router.replace('MyFlightPlans');
            });


        },

        submitfplbutton() {
            const self = this;  
            self.flightplan.fpl.meta.status = "submitted";
            self.flightplan.timetableitem.status = "submitted";
            var rflightplan = firestore.collection('flightplan').doc(self.flightplan.fpl.id)
            var rtimetableitem = firestore.collection('timetable').doc(self.flightplan.timetableitem.id)
            var batch = firestore.batch();
            batch.set(rflightplan, classToPlain(self.flightplan.fpl));
            batch.set(rtimetableitem, classToPlain(self.flightplan.timetableitem));
            batch.commit().then(function () {
                self.sendFplToEmail(self.flightplan.fpl);
                console.log("Flightplan successfully saved!");
                self.$store.commit('resetStateFlightplan');
                self.$router.replace('MyFlightPlans');
            });
        },

        
    },
    created() {    
        const self = this;

        new Promise(function(resolve) {
            if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
            resolve(1);
            }).then(function(result) { 
            if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
            return result;
            }).then(function(result) { 
            if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber)
            return result;
            });
       
    }       

}


</script>