/* eslint-disable */
import moment from 'moment'

export default class FlightPlan {

  constructor(id, aircraft, departureAerodrome, destinationAerodrome, departureDateTimeInitial, departureDateTime, durationMin, route, level, typeofflight, persons, crew, meta) {
    this.id = id;
    this.aircraft = aircraft;
    this.departureAerodrome = departureAerodrome;
    this.destinationAerodrome = destinationAerodrome;
    this.departureDateTimeInitial = departureDateTimeInitial;
    this.departureDateTime = departureDateTime;
    this.durationMin = durationMin;
    this.route = route;
    this.level = level;
    this.typeofflight = typeofflight;
    this.persons = persons;    
    this.crew = crew;
    this.meta = meta;
  }


}