<template>

      <v-card class="grey lighten-2" v-if="showUpdateUI">        
        <v-card-actions class="justify-center">
          <v-btn small class="mx-2 white--text" @click="update" color="teal"><v-icon color="white">{{ icons.mdiUpdate }}</v-icon> UPDATE</v-btn>
          <!-- <v-btn small class="mx-2" @click="showUpdateUI = false" color="red"><v-icon color="white">{{ icons.mdiCancel }}</v-icon></v-btn>  -->        
        </v-card-actions>
      </v-card>    

</template>

<script>

import {
    mdiUpdate,
    mdiCancel
  } from '@mdi/js'

export default {
  name: 'UpdatePwa',

  data: () => ({
    showUpdateUI: false,
    icons: {
        mdiUpdate,
        mdiCancel,
    }
  }),

  methods: {
    async update() {
      this.showUpdateUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  },

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
  }

};
</script>
