/* eslint-disable */
import { functions } from "./firebase";
import moment from 'moment'

export default {
  data() {},
  created: function() {},

  methods: {

   sendFplToEmail(fpl) {
      try {
         var departureDateTime = '';
         var emailrecepient;
         if (typeof (fpl.departureDateTime.seconds) == "number") {
            departureDateTime = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("YYYY-MM-DD HH:mm");
         } else {
            departureDateTime = moment.utc(fpl.departureDateTime).format("YYYY-MM-DD HH:mm");
         }
         if (fpl.destinationAerodrome.code == "LUKK" || fpl.destinationAerodrome.code == "LUBM" || fpl.departureAerodrome.code == "LUKK" || fpl.departureAerodrome.code == "LUBM") {
            emailrecepient = functions.httpsCallable('emailmoldatsaaro');
         } else {
            emailrecepient = functions.httpsCallable('emailmoldatsafmp');
         }         
         emailrecepient({ 
               subject: 'FPL ' +fpl.aircraft.tailnumber + ' ' + fpl.departureAerodrome.code + '-' 
                     + fpl.destinationAerodrome.code + ' ' + departureDateTime + 'Z',
               body: this.generatefplmessage(fpl),
               email: fpl.crew.email
         })
         .then(() => {
            console.log("Email fpl sent")
         });
      }
      catch {
         console.log("Error sending email fpl")
      }
   },

   sendDlaToEmail(fpl) {
      try {
         var departureDateTime = '';
         var emailrecepient;
         if (typeof (fpl.departureDateTime.seconds) == "number") {
            departureDateTime = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("YYYY-MM-DD HH:mm");
         } else {
            departureDateTime = moment.utc(fpl.departureDateTime).format("YYYY-MM-DD HH:mm");
         }
         if (fpl.destinationAerodrome.code == "LUKK" || fpl.destinationAerodrome.code == "LUBM" || fpl.departureAerodrome.code == "LUKK" || fpl.departureAerodrome.code == "LUBM") {
            emailrecepient = functions.httpsCallable('emailmoldatsaaro');
         } else {
            emailrecepient = functions.httpsCallable('emailmoldatsafmp');
         }  
         emailrecepient({ 
               subject: 'DLA ' + fpl.aircraft.tailnumber + ' ' + fpl.departureAerodrome.code + '-' 
                     + fpl.destinationAerodrome.code + ' ' + departureDateTime  + 'Z',
               body: this.generatedlamessage(fpl),
               email: fpl.crew.email
         })
         .then(() => {
            console.log("Email dla sent")
         });
      }
      catch {
         console.log("Error sending email dla")
      }
   },

   sendArrToEmail(fpl) {
      try {
         var departureDateTime = '';
         var emailrecepient;
         if (typeof (fpl.departureDateTime.seconds) == "number") {
            departureDateTime = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("YYYY-MM-DD HH:mm");
         } else {
            departureDateTime = moment.utc(fpl.departureDateTime).format("YYYY-MM-DD HH:mm");
         }
         if (fpl.destinationAerodrome.code == "LUKK" || fpl.destinationAerodrome.code == "LUBM" || fpl.departureAerodrome.code == "LUKK" || fpl.departureAerodrome.code == "LUBM") {
            emailrecepient = functions.httpsCallable('emailmoldatsaaro');
         } else {
            emailrecepient = functions.httpsCallable('emailmoldatsafmp');
         }  
         emailrecepient({ 
               subject: 'ARR ' + fpl.aircraft.tailnumber + ' ' + fpl.departureAerodrome.code + '-' 
                     + fpl.destinationAerodrome.code + ' ' + departureDateTime  + 'Z',
               body: this.generatearrmessage(fpl),
               email: fpl.crew.email
         })
         .then(() => {
            console.log("Email arr sent")
         });
      }
      catch {
         console.log("Error sending email arr")
      }
   },

   ConvertDEGToDMS(deg, lat) {
      const zeroPad = (num, places) => String(num).padStart(places, '0');
      var absolute = Math.abs(deg);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);
  
      if (lat) {
          var direction = deg >= 0 ? "N" : "S";
      } else {
          var direction = deg >= 0 ? "E" : "W";
          degrees = zeroPad(degrees,3)
      }
  
      return degrees + "" + zeroPad(minutes,2) + "" + direction;
   },

   generatefplmessage(fpl) {
      try {
         const zeroPad = (num, places) => String(num).padStart(places, '0');
         var departureTime = ''
         var departureDate = ''
         var typeofflight = ''
         var departureGeo = this.ConvertDEGToDMS(fpl.departureAerodrome.geo.x_, true) + this.ConvertDEGToDMS(fpl.departureAerodrome.geo.N_, false)
         var destinationGeo = this.ConvertDEGToDMS(fpl.destinationAerodrome.geo.x_, true) + this.ConvertDEGToDMS(fpl.destinationAerodrome.geo.N_, false)
         if (typeof (fpl.departureDateTime.seconds) == "number") {
            departureTime = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("HHmm");
            departureDate = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("YYMMDD");
         } else {
            departureTime = moment.utc(fpl.departureDateTime).format("HHmm");
            departureDate = moment.utc(fpl.departureDateTime).format("YYMMDD");
         }
         if (fpl.route == '') fpl.route = 'DCT';
         if (fpl.typeofflight == 'Training organization flight') typeofflight = ' TYPE TRAINING FLIGHT';
         if (fpl.typeofflight == 'Parachute jumping flight') typeofflight = ' TYPE PARACHUTE JUMPING';
      
         var row = '';
         row += "(FPL-" + fpl.aircraft.tailnumber + "-VG" + '\n';
         row += "-" + fpl.aircraft.type + "/" + fpl.aircraft.wake + "-" + fpl.aircraft.equipment + '\n';
         row += "-" + fpl.departureAerodrome.code + departureTime + '\n';
         row += "-N" + zeroPad(fpl.aircraft.speed,4) + fpl.level + " " + departureGeo + " DCT " + fpl.route + ' ' + destinationGeo + ' DCT\n';
         row += "-" + fpl.destinationAerodrome.code + zeroPad(moment.duration(fpl.durationMin, 'minutes').hours(),2) +  zeroPad(moment.duration(fpl.durationMin, 'minutes').minutes(),2) + " " + fpl.departureAerodrome.code + '\n';       
         row += "-DOF/" + departureDate + " OPR/PRIVATE ";
         row += "RMK/PIC " + fpl.crew.firstname.toUpperCase() + ' ' + fpl.crew.lastname.toUpperCase() + ' ';
         if (fpl.departureAerodrome.code == 'ZZZZ') {
            row += "DEP/" + departureGeo + ' ' + fpl.departureAerodrome.name.replace('ZZZZ | ','').toUpperCase() + ' ';
         }
         if (fpl.destinationAerodrome.code == 'ZZZZ') {
            row += "DEST/" + destinationGeo + ' ' + fpl.destinationAerodrome.name.replace('ZZZZ | ','').toUpperCase() + ' ';
         }
         row += typeofflight + " POB" + zeroPad(fpl.persons,3) + " CONTACT " + fpl.crew.phone.replace('+','00') + ")";
         
         row = row.replace(/  +/g, ' ');
         return row; 
      }
      catch {
         return '';
      }    
   },

   generatedlamessage(fpl) {
      try {         
         //const zeroPad = (num, places) => String(num).padStart(places, '0');
         //if (fpl.meta.delayeddatetime != '') {
            var departureTime = ''
            var departureDate = ''
            if (typeof (fpl.departureDateTime.seconds) == "number") {
               departureTime = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("HHmm");
               departureDate = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("YYMMDD");
            } else {
               departureTime = moment.utc(fpl.departureDateTime).format("HHmm");
               departureDate = moment.utc(fpl.departureDateTime).format("YYMMDD");
            }
            var row = '';
            row += "(DLA-" + fpl.aircraft.tailnumber + "-" + fpl.departureAerodrome.code + departureTime + "-" + fpl.destinationAerodrome.code + "-DOF/" + departureDate + ')';
            return row; 
         //}
      }
      catch {
         return '';
      }    
   },

   generatearrmessage(fpl) {
      try {         
         const zeroPad = (num, places) => String(num).padStart(places, '0');
         //if (fpl.meta.delayeddatetime != '') {
            var departureTime = ''
            var departureDate = ''
            if (typeof (fpl.departureDateTime.seconds) == "number") {
               departureTime = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("HHmm");
               departureDate = moment.utc(moment.unix(fpl.departureDateTime.seconds)).format("YYMMDD");
            } else {
               departureTime = moment.utc(fpl.departureDateTime).format("HHmm");
               departureDate = moment.utc(fpl.departureDateTime).format("YYMMDD");
            }
            var row = '';
            row += "(ARR-" + fpl.aircraft.tailnumber + "-" + fpl.departureAerodrome.code + departureTime + "-" + fpl.destinationAerodrome.code +
            zeroPad(moment.duration(fpl.durationMin, 'minutes').hours(),2) +  zeroPad(moment.duration(fpl.durationMin, 'minutes').minutes(),2) + "-DOF/" + departureDate + ')';
            return row; 
         //}
      }
      catch {
         return '';
      }    
   },

   
  },
};
