<!-- eslint-disable -->
<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-icon color="white" class="mr-3">{{ icons.mdiAirplane }}</v-icon>
          Flight Plan Delay
 </v-app-bar> 
<v-main class="pa-0">
        <v-container fluid class="pa-4">
            <v-layout wrap>
                <v-flex class="">
                    <v-form ref="form" v-model="valid" >
                        <v-text-field v-model="aircraft" label="Aircraft identification" readonly filled dense></v-text-field>
                        <v-text-field v-model="departureAerodrome" label="Departure aerodrome" readonly filled dense></v-text-field>
                        <v-text-field v-model="destinationAerodrome" label="Destination aerodrome" readonly filled dense></v-text-field>
                        <v-text-field v-model="departureDate" label="Day of flight" readonly filled dense></v-text-field>
                        <v-text-field v-model="departureTime" label="Original departure time" readonly filled dense></v-text-field>
                                                
                        <v-menu v-model="showDepTimePicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="delayedTime" label="Delayed time (UTC)" persistent-hint readonly v-on="on" required :rules="delayedTimeRules" />
                            </template>
                            <v-time-picker v-model="delayedTime" format="24hr" :allowed-minutes="allowedMinutes" :allowed-hours="allowedHours" @input="showDepTimePicker = false" />
                        </v-menu> 

                      <v-layout row child-flex justify-center align-center wrap pa-4>
                        <v-btn color="red" class="white--text" :to="{name:'MyFlightPlans'}" >
                            <v-icon left dark> {{ icons.mdiKeyboardBackspace }} </v-icon>
                            BACK                    
                        </v-btn>
                        <v-spacer />
                        <v-btn :disabled="!valid" color="success" class="white--text" @click="delayFpl(fpl.id)">
                            DELAY
                            <v-icon right dark> {{ icons.mdiContentSave }} </v-icon>
                        </v-btn>                        
                      </v-layout>
                    </v-form>
                </v-flex>
            </v-layout>
        </v-container>
    </v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>
/* eslint-disable */
import Menu from '../components/menu.vue'
import { auth, firestore } from '../firebase'
import { mapFields } from 'vuex-map-fields';  
import { mdiAirplane, mdiKeyboardBackspace } from '@mdi/js'
import moment from 'moment'
import mixin from '../mixin'

export default {
    name: 'DelayFpl',

    components: {
        Menu
    },

    mixins: [mixin],

    data: () => ({ 
        icons: {
          mdiAirplane, mdiKeyboardBackspace
        },
        activeBtn: 'myfpl',
        valid: true,
        // fplid: null,
        showDepTimePicker: false,
        delayedTime: null,
        allowedMinutes: m => m % 5 === 0
    }),

    computed: {
       ...mapFields({
            MyFlights: 'myflights'
       }),
       delayedTimeRules(v) {
        const self = this;
        return [ function(v1) {
          if (v1 > moment.utc(moment.unix(self.fpl.departureDateTime.seconds)).format("HH:mm")) return true; else return false; 
        }]
       },

       fpl() {
        const self = this;
        if (typeof (self.$route.query.fplid) == "undefined") {
           self.$router.replace('myfpl')
          } else {
            return self.$store.state.myflights[self.$store.state.myflights.findIndex((obj => obj.id == self.$route.query.fplid))];
          }
       },
       aircraft() {
        const self = this;
        if (typeof (self.fpl) != "undefined") {
          return self.fpl.aircraft.tailnumber + ' (' + self.fpl.aircraft.type + ')';
        } else return "";
       },
       departureAerodrome() {
        const self = this;
        if (typeof (self.fpl) != "undefined") {
          return this.fpl.departureAerodrome.name;
        } else return "";        
       },
      destinationAerodrome() {
        const self = this;
        if (typeof (self.fpl) != "undefined") {
         return this.fpl.destinationAerodrome.name;
        } else return "";
       },
       departureDate() {
        const self = this;
        if (typeof (self.fpl) != "undefined") {
         if (typeof (this.fpl.departureDateTime.seconds) == "number") {
            return moment.utc(moment.unix(this.fpl.departureDateTime.seconds)).format("YYYY-MM-DD");
         } else {
            return moment.utc(this.fpl.departureDateTime).format("YYYY-MM-DD");
         }
        } else return "";     
       },
       departureTime() {
        const self = this;
        if (typeof (self.fpl) != "undefined") {
         if (typeof (this.fpl.departureDateTime.seconds) == "number") {
            return moment.utc(moment.unix(this.fpl.departureDateTime.seconds)).format("HH:mm");
         } else {
            return moment.utc(this.fpl.departureDateTime).format("HH:mm");
         }
        } else return "";     
       },
       allowedHours() {
         const self = this;
         var hours = [];
         var i;
         if (typeof (self.fpl) != "undefined") {
          if (typeof (self.fpl.departureDateTime.seconds) == "number") {            
              for (i = 0; i < 5; i++) {
                hours.push(parseInt(moment.utc(moment.unix(self.fpl.departureDateTime.seconds)).add(i, 'hours').format("HH")));
              }
          } else {
            for (i = 0; i < 5; i++) {
                hours.push(parseInt(moment.utc(self.fpl.departureDateTime).add(i, 'hours').format("HH")));
              }
          }
         }
         return hours;        
       }

    },

    methods: {
        momentunix(m) {
            return moment.unix(m);
        },
        momentutc(m) {
            return moment.utc(m);
        },
        
        delayFpl(id) {
            const self = this;
            var delayeddatetime = new Date(moment.utc(self.departureDate + ' ' + self.delayedTime));
            var rflightplan = firestore.collection('flightplan').doc(id)
            var rtimetableitem = firestore.collection('timetable').doc(id)
            var batch = firestore.batch();
            batch.update(rflightplan, { "meta.status": "delayed", "meta.delayeddatetime": delayeddatetime, "departureDateTime": delayeddatetime } );
            batch.update(rtimetableitem, { "status": "delayed", "updatedDateTime": new Date(), "departureDateTime": delayeddatetime, "arrivalDateTime": moment(delayeddatetime).add(self.fpl.durationMin, 'm').toDate() } );
            batch.commit().then(function () {
                var payload = [id, delayeddatetime];
                self.$store.commit('delayFpl', payload);       
                self.sendDlaToEmail(self.fpl);
                console.log("Flightplan successfully delayed!");
                self.$router.replace('MyFlightPlans');
            });
          
        },

    },
    created() {
        const self = this;
        
        new Promise(function(resolve) {
          if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
          resolve(1);
        }).then(function(result) { 
          if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.myflights[0]) self.$store.commit('updateFlightsFromDb', auth.currentUser.phoneNumber);
          return result;
        });
        var fpl = self.$store.state.myflights[self.$store.state.myflights.findIndex((obj => obj.id == self.$route.query.fplid))];
        
        

        
    }       

}


</script>