<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-icon color="white" class="mr-3">{{ icons.mdiAccount }}</v-icon>
          Account
 </v-app-bar> 
<v-main class="pa-0">
  <v-container fluid class="pa-4">
   <v-layout wrap>
       <v-flex class="">
       <v-form ref="form" v-model="valid">
            <v-text-field  v-model="phone" label="Phone number" required readonly ></v-text-field> 
            <v-text-field  v-model="firstname" :rules="firstnameRules" label="First name" required ></v-text-field> 
            <v-text-field v-model="lastname" :rules="lastnameRules" label="Last name" required ></v-text-field> 
            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required ></v-text-field> 
            <v-text-field v-model="licenseno" :rules="licensenoRules" label="License number" required ></v-text-field>  
            <v-select  v-model="selectedAircrafts" :items="availableAircrafts" item-text="tailnumberfull" item-value="id"
                label="Aircrafts" multiple chips hint="Select your aircrafts" persistent-hint>
            </v-select>
            <v-checkbox v-model="agree" color="red darken-3" value="" required :rules="agreeRules">
              <template v-slot:label>
                <div> I have read and will follow  
                  <a target="_blank" href="/docs/FLIGHT_RULES_2021.pdf" @click.stop v-on="on">
                    Aerodrome Flight Rules
                  </a> 
                </div>
              </template>
            </v-checkbox>
             
            <v-layout row child-flex justify-center align-center pa-4>
              <v-spacer />
                <v-btn :disabled="!valid" color="success" class="white--text" @click="updateAccount">
                  SAVE
                  <v-icon right dark> {{ icons.mdiContentSave }} </v-icon>
                </v-btn>
              <v-spacer />
            </v-layout>
        </v-form>


               
       </v-flex>
   </v-layout>
  <v-layout wrap class="mt-6 ml-1 mr-1" justify-center align-center>
    <v-btn color="indigo" class="white--text ma-3" @click="logbookbutton">
      LOGBOOK
      <v-icon right dark> {{ icons.mdiBookOpenOutline }} </v-icon>
    </v-btn>
    <v-btn color="indigo" class="white--text ma-3" @click="timetablebutton">
      TIMETABLE
      <v-icon right dark> {{ icons.mdiBookOpenOutline }} </v-icon>
    </v-btn>
    <v-btn color="teal" class="white--text ma-3" @click="paymentsbutton">
      PAY ONLINE
      <v-icon right dark> {{ icons.mdiCreditCardOutline }} </v-icon>
    </v-btn>
    <v-btn color="pink" class="white--text ma-3" @click="logoutbutton">
      LOGOUT
      <v-icon right dark> {{ icons.mdiLogout }} </v-icon>
    </v-btn>   
  </v-layout>
   
  </v-container>


</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>

  import Menu from '../components/menu.vue'
  import { auth } from '../firebase'
  import { mapFields } from 'vuex-map-fields';
  import { mdiAccount, mdiLogout, mdiContentSave, mdiBookOpenOutline, mdiCreditCardOutline } from '@mdi/js'
 
  export default {
    name: 'Account',

    components: {
      Menu
    },

    data: () => (
      { 
        icons: {
          mdiAccount,
          mdiLogout,
          mdiContentSave,
          mdiBookOpenOutline,
          mdiCreditCardOutline
        },
        activeBtn: 'account',
        valid: true,
        firstnameRules: [
            v => !!v || 'First name is required',
            v => (v && v.length >= 3) || 'Minimum length is 3 characters',
        ],
        lastnameRules: [
            v => !!v || 'Last name is required',
            v => (v && v.length >= 4) || 'Minimum length is 4 characters',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        licensenoRules: [
            v => !!v || 'License number is required',
            v => (v && v.length >= 5) || 'Minimum length is 5 characters',
        ],
        agreeRules: [
            v => !!v || 'Agree is required'
        ],
        on: ''
      }),

    methods: {
        updateAccount() {
            this.$store.commit('updateAccountToDb');
        },
        logbookbutton() {
            this.$router.replace('logbook')
        },
        timetablebutton() {
            this.$router.replace('flighttimetable')
        },
        paymentsbutton() {
            this.$router.replace('payments')
        },
        logoutbutton() {
          auth.signOut().then(() => {
            this.$store.commit('resetState');
            this.$router.replace('loginbyphone')
          })
        }

    },

    computed: {
        ...mapFields({
            phone: 'account.phone',
            firstname: 'account.firstname',
            lastname: 'account.lastname',
            email: 'account.email',
            licenseno: 'account.licenseno',
            agree: 'account.agree',
            selectedAircrafts: 'account.aircraft',
            availableAircrafts: 'availableAircrafts'
            }),
    },

    created() {
        const self = this;
        self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
        if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber);
        if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
    }   
    
  }
</script>
