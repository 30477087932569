<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-icon color="white" class="mr-3">{{ icons.mdiInformationOutline }}</v-icon>
          Briefing
 </v-app-bar> 
<v-main class="pa-0">
  <v-container fluid class="pa-3 ma-0">
        <v-tabs v-model="tab" centered show-arrows="mobile" class="ma-0 pa-0">
          <v-tab key="metar">METAR</v-tab>
          <v-tab key="taf">TAF</v-tab>
          <v-tab key="notam">NOTAM</v-tab>
          <v-tab key="webcam">WEBCAM</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="mt-3 pa-1">
            <v-tab-item key="metar" >
                <v-card >
                    <v-card-title class="ma-0 pa-1 justify-center" >
                        <span>{{ metar_icao }}</span>
                        <v-badge
                            :content="metar_flightcategory"
                            :value="metar_flightcategory"
                            :color="metar_flightcategorycolor"
                            overlap
                            offset-x="-10"
                            offset-y="0"
                        >
                        </v-badge>
                    </v-card-title> 
                    <v-card-subtitle class="ma-0 pa-0 text-center"> {{ metar_station }} </v-card-subtitle>       
                    <v-card-subtitle  class="ma-0 pa-0 text-center"> {{ metar_observed }} </v-card-subtitle>               
                    <v-card-text class="ma-0 pa-0">
                        <v-simple-table dense class="ma-0 pa-1">
                            <tbody>
                                <tr> <td> Wind </td> <td> <span style="white-space: pre;">{{ metar_wind }}</span> </td> </tr>
                                <tr> <td> Visibility </td> <td> 
                                    <template v-if="metar_visibility != ''"> <span style="white-space: pre;">{{ metar_visibility }} meters</span> </template> 
                                    <template v-if="metar_verticalvisibility != ''"> <span style="white-space: pre;">vertical visibility {{ metar_verticalvisibility }} ft</span> </template> 
                                </td> </tr>
                                <tr> <td> Clouds </td> <td> <span style="white-space: pre;">{{ metar_clouds }}</span> </td> </tr>
                                <tr> <td> Temperature </td> <td> <span style="white-space: pre;">{{ metar_temperature }}</span> </td> </tr>
                                <tr> <td> Dewpoint </td> <td> <span style="white-space: pre;">{{ metar_dewpoint }}</span> </td> </tr>                                
                                <tr> <td> Humidify </td> <td> <span style="white-space: pre;">{{ metar_humidity }}</span> </td> </tr>
                                <tr> <td> Pressure </td> <td> <span style="white-space: pre;">{{ metar_pressure }}</span> </td> </tr>       
                                <tr v-if="metar_conditions != ''"> 
                                    <td> Conditions </td>
                                    <td>                                        
                                        <span class="newlineclass" v-for="(condition,index) in metar_conditions" :key="index">
                                            {{ condition.text }} 
                                        </span>                                        
                                    </td>
                                </tr>                                                                

                                <tr> <td colspan="2"><span class="orange--text text--darken-3 newlineclass">Raw</span>{{ metar_raw}}</td> </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="taf" >
                <v-card >
                    <v-card-title class="ma-0 pa-1 justify-center" >
                        <span>{{ taf_icao }}</span>                                            
                    </v-card-title> 
                    <v-card-subtitle class="ma-0 pa-0 text-center"> {{ taf_station }} </v-card-subtitle>       
                    <v-card-subtitle  class="ma-0 pa-0 text-center">Bulletin: {{ taf_bulletin }} </v-card-subtitle>                                       
                    <v-card-text class="ma-0 pa-0">
                        <v-simple-table dense class="ma-0 pa-1">
                            <tbody>
                                <tr> <td> From </td> <td> <span style="white-space: pre;">{{ taf_from }}</span> </td> </tr>
                                <tr> <td> To </td> <td> <span style="white-space: pre;">{{ taf_to }}</span> </td> </tr>
                                <tr v-for="(item, index) in taf_forecast" :key="index"> <td colspan="2"> 
                                    <span class="orange--text text--darken-3 newlineclass">
                                        <template v-if="item.hasOwnProperty('change')"> {{ item.change.indicator.text }} </template>
                                        <template v-else> Forecast </template>
                                        {{ moment(item.timestamp.from).format("HH:mm (DD.)") }} to {{ moment(item.timestamp.to).format("HH:mm (DD.)") }}
                                    </span>
                                    <span class="newlineclass">
                                        <template v-if="item.hasOwnProperty('wind')">Wind {{ item.wind.degrees }}° at {{ item.wind.speed_kts }} knots</template>
                                    </span>
                                    <span class="newlineclass">
                                        <template v-if="item.hasOwnProperty('visibility')">Visibility {{ item.visibility.meters }} m</template>
                                    </span>
                                    <!-- <span class="newlineclass">                                        
                                        <template v-if="item.hasOwnProperty('ceiling')"> 
                                            {{ item.ceiling.text }} clouds at {{ item.ceiling.base_feet_agl }} ft                                            
                                        </template>
                                    </span> -->
                                    <span class="newlineclass">
                                        <template v-if="item.hasOwnProperty('clouds')">
                                            <span class="newlineclass"> <template v-for="cloud in item.clouds">
                                                {{ cloud.text }} <template v-if="cloud.hasOwnProperty('base_feet_agl')"> clouds at {{ cloud.base_feet_agl }} ft </template>
                                            </template></span>
                                        </template>
                                    </span>
                                    <span class="newlineclass">
                                        <template v-if="item.hasOwnProperty('conditions')">
                                            <span class="newlineclass"> <template v-for="condition in item.conditions">
                                                {{ condition.text }}                                                 
                                            </template></span>
                                        </template>
                                    </span>

                                </td></tr>
                                                          
                                <tr> <td colspan="2">
                                    <span class="orange--text text--darken-3 newlineclass">Raw</span>
                                    {{ taf_raw }}
                                </td> </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="notam">
               <v-card >
                    <v-card-title class="ma-0 pa-1 justify-center" >
                        <span>{{ notam_icao }}</span>
                    </v-card-title> 
                    <v-card-subtitle class="ma-0 pa-0 text-center"> {{ notam_updated }} </v-card-subtitle>                 
                    <v-card-text class="ma-0 pa-0">
                        <v-simple-table dense class="ma-0 pa-1">
                            <tbody>
                                <tr v-for="(item, index) in notam_items" :key="index"> <td colspan="2"> 
                                    <span class="orange--text text--darken-3 newlineclass">
                                        <template v-if="item.hasOwnProperty('startvalidity')">From: {{ momentunix(item.startvalidity).format("DD.MM.YYYY HH:mm") + ' LT' }} </template>
                                    </span>             
                                    <span class="orange--text text--darken-3 newlineclass">                           
                                        <template v-if="item.hasOwnProperty('endvalidity')">To: {{ momentunix(item.endvalidity).format("DD.MM.YYYY HH:mm") + ' LT' }} </template>
                                    </span>
                                    <span class="newlineclass">                           
                                        <template v-if="item.hasOwnProperty('itemd')">{{ item.itemd }} </template>
                                    </span>
                                    <span class="newlineclass">                           
                                        <template v-if="item.hasOwnProperty('iteme')">{{ item.iteme }} </template>
                                    </span>
                                    <span class="newlineclass">                           
                                        <template v-if="item.hasOwnProperty('itemf')">{{ item.itemf }} </template>
                                    </span>
                                    <span class="newlineclass">                           
                                        <template v-if="item.hasOwnProperty('itemg')">{{ item.itemg }} </template>
                                    </span>
                                    </td>
                                </tr>                                
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>    

            <v-tab-item key="webcam">
                <v-row align="center" justify="center">
                <v-col>
                    <VideoPlayer
                        class="vjs-custom-skin pa-1"
                        :options="playerOptions"
                        :playsinline="true"
                    />
                </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col class="text-center">                        
                            <v-btn tile color="indigo" outlined class="pa-2 ma-2" v-for="(webcam,index) in webcams" :key="index" @click="selectedwebcam = index">
                            {{ webcam.name }}
                            </v-btn>                        
                    </v-col>
                </v-row>             
            </v-tab-item> 

        </v-tabs-items>



  </v-container>
</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>

<style scoped>
    .newlineclass { display: block; }
</style>

<script>
/* eslint-disable */
import Menu from '../components/menu.vue'
import { mdiInformationOutline } from '@mdi/js'
import moment from 'moment'
import { auth, functions, firestore } from '../firebase'
import axios from 'axios'
import VideoPlayer from 'vue-video-player-vjs'
import 'video.js/dist/video-js.css'
import 'vue-video-player-vjs/styles/index.css'

export default {
    name: 'Briefing',

    components: {
        Menu,
        VideoPlayer
    },

    data: () => ({         
        icons: {
          mdiInformationOutline
        },
        activeBtn: 'briefing',
        valid: true,
        tab: null,
        metar: {
            icao: '',
            station: {
                name: ''
            },
            observed: '',
            wind: {
                degrees: '',
                speed: ''
            },
            visibility: {
                meters_float: '',
                vertical: {
                    feet: ''
                }
            },
            clouds: [],
            temperature: {
                celsius: ''
            },
            dewpoint: {
                celsius: ''
            },
            humidity: {
                percent: ''
            },
            barometer: {
                hpa: ''
            },
            raw: '',
            flight_category: '',
            conditions: []
        },
        flightconditions: 'VFR',
        flightconditionscolor: 'green',
        taf: {
            icao: '',
            station: {
                name: ''
            },
            timestamp: {
                bulletin: '',
                from: '',
                to: ''
            },
            raw_text: '',
            forecast: []
        },
        notams: [],
        webcams: [],
        metar_updated: '',
        taf_updated: '',
        notams_updated: {
            seconds: ''
        },
        selectedwebcam: 0
    }),

    computed: {

        playerOptions() {
            var url = '';
            if (typeof this.webcams[0] !== "undefined") url = this.webcams[this.selectedwebcam].url;
            var option = {
                autoplay: true, 
                controls: true, 
                preload: 'none',
                language: 'en-US',
                fluid: true, 
                aspectRatio: '16:9', 
                notSupportedMessage: 'not supported',
                sources: [{
                    withCredentials: false,
                    type: 'application/x-mpegURL',
                    src: url
                }
                ],
                controlBar: {
                    timeDivider: false,
                    remainingTimeDisplay: false,
                    currentTimeDisplay: false,
                    durationDisplay: false,
                    volumePanel: false,
                    durationDisplay: false,
                    fullscreenToggle: true,
                    liveDisplay: false,
                    subsCapsButton: false,
                    pictureInPictureToggle: false
                }
            }
            return option;
        },

        metar_icao() {
            return this.metar.icao;
        },
        metar_station() {
            return this.metar.station.name;
        },
        metar_observed() {
            return moment(this.metar.observed).format("DD.MM.YYYY HH:mm") + ' LT'
        },
        metar_wind() {
            const zeroPad = (num, places) => String(num).padStart(places, '0');
            return zeroPad(this.metar.wind.degrees,3) + '° at ' + this.metar.wind.speed_kts + ' knots';
        },
        metar_visibility() {
            if (this.metar.visibility.hasOwnProperty('meters_float')) {
                return this.metar.visibility.meters_float;
            } else {
                return ''
            }            
        },
        metar_verticalvisibility() {
            if (this.metar.visibility.hasOwnProperty('vertical')) {
                return this.metar.visibility.vertical.feet;
            } else {
                return ''
            }
        },
        metar_clouds() {
            var clouds = '';
            this.metar.clouds.forEach(item => { clouds += item.code + ' | ' + item.text + '\n' });
            return clouds;
        },
        metar_temperature() {
            return this.metar.temperature.celsius + '°C';
        },
        metar_dewpoint() {
            return this.metar.dewpoint.celsius  + '°C';
        },
        metar_humidity() {
            return this.metar.humidity.percent  + '%';
        },
        metar_pressure() {
            return this.metar.barometer.hpa  + ' hPa';
        },
        metar_raw() {
            return this.metar.raw_text;
        },
        metar_conditions() {
            if (this.metar.hasOwnProperty('conditions')) {
                return this.metar.conditions;
            } else {
                return ''
            }
            return this.metar.conditions;
        },
        metar_flightcategory() {
            return this.metar.flight_category;
        },
        metar_flightcategorycolor() {
            switch(this.metar_flightcategory) {
            case 'VFR':
              return 'green lighten-1';
            case 'MVFR':
              return 'blue lighten-1';
            case 'IFR':
              return 'red lighten-1';
            case 'LIFR':
              return 'pink lighten-1';
            default:
              return 'green lighten-1';
          }  
        },

        taf_icao() {
            return this.taf.icao;
        },
        taf_station() {
            return this.taf.station.name;
        },
        taf_bulletin() {
            return moment(this.taf.timestamp.bulletin).format("DD.MM.YYYY HH:mm") + ' LT'
        },
        taf_from() {
            return moment(this.taf.timestamp.from).format("DD.MM.YYYY HH:mm") + ' LT'
        },
        taf_to() {
            return moment(this.taf.timestamp.to).format("DD.MM.YYYY HH:mm") + ' LT'
        },
        taf_raw() {
            return this.taf.raw_text;
        },
        taf_forecast() {
            return this.taf.forecast;
        },

        notam_icao() {
            return 'LUUU FIR'
        },
        notam_updated() {
            if (typeof (this.notams_updated.seconds) == "number") {
                    return moment.unix(this.notams_updated.seconds).format("DD.MM.YYYY HH:mm") + ' LT'
                } else {
                    return moment(this.notams_updated).format("DD.MM.YYYY HH:mm") + ' LT'
                }
        },
        notam_items() {
            return this.notams;
        },

    },

    methods: {
        
        change() {
            this.playerOptions.sources[0].src = 'https://cams.aeroclub.md/cam/nistru02/playlist.m3u8'
        },

        moment(d) {
            return moment(d);
        },
        momentunix(d) {
            return moment.unix(d)
        },

        getMetar() {
             firestore
                .collection('briefing').doc('metar').get()
                .then(snap => {
                    if ( moment.unix(snap.data().updated.seconds).toDate() > moment().add(-5, 'minutes').toDate() ) {     
                        var result = null;               
                        result = snap.data();
                        //console.log("FIRESTORE",result)
                        this.metar = result.data;
                        this.metar_updated = result.updated;
                        return result.data;
                    } else {
                        var getMetar = functions.httpsCallable('getMetar');
                        getMetar({ code: 'LUKK', count: 100 })
                        .then(response => {    
                            var result = null;
                            result = {
                                id: "metar",
                                updated: response.data.updated,
                                data: response.data.data,
                            };        
                            //console.log("FUNCTION", result)
                            this.metar = result.data;
                            this.metar_updated = result.updated;
                            return result.data;
                        });
                    }
                });
        },

        getTaf() {            
            firestore
                .collection('briefing').doc('taf').get()
                .then(snap => {
                    if ( moment.unix(snap.data().updated.seconds).toDate() > moment().add(-5, 'minutes').toDate() ) { 
                        var result = null;                   
                        result = snap.data();
                        //console.log("FIRESTORE",result)
                        this.taf = result.data;  
                        this.taf_updated = result.updated; 
                        return result.data;
                    } else {
                        var getTaf = functions.httpsCallable('getTaf');
                        getTaf({ code: 'LUKK', count: 100 })
                        .then(response => {    
                            var result = null;
                            result = {
                                id: "taf",
                                updated: response.data.updated,
                                data: response.data.data,
                            };                            
                            //console.log("FUNCTION", result)
                            this.taf = result.data;
                            this.taf_updated = result.updated;
                            return result.data;
                        });
                    }
                });
        },

        getNotams() {            
            firestore
                .collection('briefing').doc('notam').get()
                .then(snap => {
                    if ( moment.unix(snap.data().updated.seconds).toDate() > moment().add(-1, 'hours').toDate() ) {    
                        var result = null;                
                        result = snap.data();
                        //console.log("FIRESTORE",result)
                        this.notams = result.data;
                        this.notams_updated = result.updated;
                        return result.data;
                    } else {
                        var getNotams = functions.httpsCallable('getNotams');
                        getNotams({ code: ['"LUKV"','"LUUU"'], count: 100 })
                        .then(response => {
                            var result = null;  
                            result = {
                                id: "notam",
                                updated: response.data.updated,
                                data: response.data.data,
                            };
                            //console.log("FUNCTION", result);
                            this.notams = result.data;
                            this.notams_updated = result.updated;
                            return result;
                        });
                    }                    
                });
        },

        getWebcam() {
             firestore
                .collection('briefing').doc('webcam').get()
                .then(snap => {
                        var result = null;               
                        result = snap.data();
                        //console.log("WEBCAM",result)
                        this.webcams = result.data;
                        return result.data;
                    });
        },


    },

    created() {

        const self = this;
        if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
        self.getMetar();
        self.getTaf();
        self.getNotams();
        self.getWebcam();


    }       

}


</script>