
export default class Crew {

  constructor(id, firstname, lastname, phone, email) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.email = email;
  }



}