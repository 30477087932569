<!-- eslint-disable -->
<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-icon color="white" class="mr-3">{{ icons.mdiAirplane }}</v-icon>
          My Flights
 </v-app-bar> 
<v-main class="pa-0">
  <v-container fluid class="pa-0">
   <v-layout wrap>
       <v-flex v-for="item in MyFlights" :key="item.id">
          <v-card
            class="ma-4 mb-0"
          >
            <v-card-title class="pink darken-2 button pa-1 pl-3 pr-3 white--text justify-space-between">
             <div>{{ item.departureAerodrome.code }} - {{ item.destinationAerodrome.code }}</div>
             <div> {{ item.aircraft.tailnumber }} </div>
            </v-card-title>

            <v-card-text :class="elementColour(item.meta.status)" class="pa-1 pl-3">
              
              {{ momentutc(momentunix(item.departureDateTime.seconds)).format("YYYY-MM-DD HH:mm") }} - {{ momentutc(momentunix(item.departureDateTime.seconds + item.durationMin*60)).format("HH:mm") }} UTC<br />
              {{ item.departureAerodrome.name.split('|')[1].toUpperCase() }} > {{ item.routeflight }} > {{ item.destinationAerodrome.name.split('|')[1].toUpperCase() }}              
            </v-card-text>

            <v-card-actions :class="elementColour(item.meta.status)" class="pa-1 pl-3">
              <v-btn color="success" dark small v-if="item.meta.status === 'created'" @click="submitFpl(item.id)">
                SUBMIT
              </v-btn>
              <v-btn color="pink" dark small v-if="item.meta.status === 'created'" @click="cancelFpl(item.id)">
                CANCEL
              </v-btn>          
              <v-btn color="teal" dark small v-if="(item.meta.status === 'submitted') || (item.meta.status === 'delayed')" @click="delayFpl(item.id)"> 
                DELAY
              </v-btn>
              <v-btn color="primary" dark small v-if="(item.meta.status === 'submitted') || (item.meta.status === 'delayed')" @click="closeFpl(item.id)">
                CLOSE
              </v-btn>

            </v-card-actions>

          </v-card>
       </v-flex>
   </v-layout>
  </v-container>
</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>
/* eslint-disable */
import Menu from '../components/menu.vue'
import { auth, firestore } from '../firebase'
import { mapFields } from 'vuex-map-fields';  
import { mdiAirplane } from '@mdi/js'
import moment from 'moment'
import mixin from '../mixin'

export default {
    name: 'MyFlightPlans',

    components: {
        Menu
    },

    mixins: [mixin],

    data: () => ({ 
        icons: {
          mdiAirplane
        },
        activeBtn: 'myfpl',
        valid: true,
        dialog: false
    }),

    computed: {
       ...mapFields({
            MyFlights: 'myflights'
       })
    },

    methods: {
        momentunix(m) {
            return moment.unix(m);
        },
        momentutc(m) {
            return moment.utc(m);
        },
        cancelFpl(id) {
            const self = this;
            var rflightplan = firestore.collection('flightplan').doc(id)
            var rtimetableitem = firestore.collection('timetable').doc(id)
            var batch = firestore.batch();
            batch.update(rflightplan, { "meta.status": "cancelled", "meta.cancelleddatetime": new Date() } );
            batch.update(rtimetableitem, { "status": "cancelled", "updatedDateTime": new Date() } );
            batch.commit().then(function () {
                self.$store.commit('cancelFpl', id);
                var fpl = self.$store.state.myflights[self.$store.state.myflights.findIndex((obj => obj.id == id))];                
                console.log("Flightplan successfully cancelled!");                
            });
        },

        submitFpl(id) {
            const self = this;
            var rflightplan = firestore.collection('flightplan').doc(id)
            var rtimetableitem = firestore.collection('timetable').doc(id)
            var batch = firestore.batch();
            batch.update(rflightplan, { "meta.status": "submitted", "meta.submitteddatetime": new Date() } );
            batch.update(rtimetableitem, { "status": "submitted", "updatedDateTime": new Date() } );
            batch.commit().then(function () {
                self.$store.commit('submitFpl', id);
                var fpl = self.$store.state.myflights[self.$store.state.myflights.findIndex((obj => obj.id == id))];                
                self.sendFplToEmail(fpl);
                console.log("Flightplan successfully submitted!");                
            });
           
        },
        
        delayFpl(id) {
            const self = this;
            var fpl = self.$store.state.myflights[self.$store.state.myflights.findIndex((obj => obj.id == id))];
            this.$router.push({ path: 'delayfpl', query: { fplid: fpl.id } })                    
        },

        closeFpl(id) {
            const self = this;
            var rflightplan = firestore.collection('flightplan').doc(id)
            var rtimetableitem = firestore.collection('timetable').doc(id)
            var batch = firestore.batch();
            batch.update(rflightplan, { "meta.status": "closed", "meta.closeddatetime": new Date() } );
            batch.update(rtimetableitem, { "status": "closed", "updatedDateTime": new Date() } );
            batch.commit().then(function () {
                self.$store.commit('closeFpl', id);
                var fpl = self.$store.state.myflights[self.$store.state.myflights.findIndex((obj => obj.id == id))];                
                self.sendArrToEmail(fpl);
                console.log("Flightplan successfully closed!");                
            });
        },


        elementColour(status) {
          switch(status) {
            case 'created':
              return 'blue-grey lighten-5';
            case 'submitted':
              return 'green lighten-3';
            case 'closed':
              return 'blue lighten-3';
            case 'cancelled':
              return 'red lighten-3';
            default:
              return 'blue-grey lighten-5';
          }                    
        },
    },
    created() {
        const self = this;
        
        new Promise(function(resolve) {
          if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
          resolve(1);
        }).then(function(result) { 
          if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          self.$store.commit('updateFlightsFromDb', auth.currentUser.phoneNumber)
          return result;
        });

        
    }       

}


</script>