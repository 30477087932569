<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-row align="center" class="pa-0">
          <v-col cols="auto">
          <v-icon color="white" class="mr-3">{{ icons.mdiBookOpenOutline }}</v-icon>
           Logbook
          </v-col>
          <v-spacer class="d-none d-sm-flex" />          
          <v-col class="d-none d-sm-flex">
          <v-select  v-model="selectedMonth" :items="availableMonths" item-text="text" item-value="value" 
            filled outlined dense hide-details dark ></v-select>
          </v-col>
   </v-row>
 </v-app-bar> 
<v-main class="pa-0">
  <v-container fluid class="pa-5">
   <v-row class="pa-0 green darken-4" align="center">
       <v-col class="pa-0">
        <v-data-table
          dense
          disable-pagination	
          disable-sort
          :headers="headerslogbook"
          :items="logbook"
          item-key="name"
          hide-default-footer
          class="rounded-0"
        ></v-data-table>
       </v-col>
    </v-row>
  </v-container>
</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>
/* eslint-disable */
import Menu from '../components/menu.vue'
import { mdiBookOpenOutline } from '@mdi/js'
import moment from 'moment'
import { auth, firestore } from '../firebase'

export default {
    name: 'Logbook',

    components: {
        Menu
    },

    data: () => ({ 
        icons: {
          mdiBookOpenOutline
        },
        activeBtn: 'account',
        valid: true,
        selectedMonth: '',
        headerslogbook: [
          { text: 'Date',  value: 'date', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Departure', value: 'depplace', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'D Time', value: 'deptime', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Arrival', value: 'arrplace', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'A Time', value:'arrtime', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Type', value:'aircrafttype', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Reg', value:'aircraftreg', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Duration', value:'totaltime', sortable:false, class: 'white--text pink darken-2 text-caption' }
        ],
        logbook: []
    }),

    computed: {
        availableMonths() {
            var i;
            var availableMonths = [];
            for (i=0; i>-6; i--) {
                availableMonths.push({
                    text: moment().add(i,'months').format("MMMM YYYY"),
                    value: moment().add(i,'months').format("YYYY-MM-01")
                })
            }
            return availableMonths;
        },
        
    },

    watch: {
        selectedMonth: function(selectedMonth) {
            const zeroPad = (num, places) => String(num).padStart(places, '0');
            this.logbook = [];
            firestore
            .collection('flightplan')
            .where("meta.userid", "==", auth.currentUser.uid)
            .where('meta.status', 'in', ['submitted', 'delayed', 'closed'])
            .where("departureDateTime", ">", moment(selectedMonth).toDate())
            .where("departureDateTime", "<", moment(selectedMonth).add(1, 'months').toDate())
            .orderBy('departureDateTime')
            .get()
            .then(snap => {
                snap.forEach(doc => {
                    this.logbook.push({
                        id: doc.data().id,
                        date: moment.utc(moment.unix(doc.data().departureDateTime.seconds)).format("YYYY-MM-DD"),
                        depplace: doc.data().departureAerodrome.code,
                        deptime: moment.utc(moment.unix(doc.data().departureDateTime.seconds)).format("HH:mm"),
                        arrplace: doc.data().destinationAerodrome.code,
                        arrtime: moment.utc(moment.unix(doc.data().departureDateTime.seconds + doc.data().durationMin*60)).format("HH:mm"),
                        aircrafttype: doc.data().aircraft.type,
                        aircraftreg: doc.data().aircraft.tailnumber,
                        totaltime: zeroPad(moment.duration(doc.data().durationMin, 'minutes').hours(),2) + ':' + zeroPad(moment.duration(doc.data().durationMin, 'minutes').minutes(),2)
                     });                   
                });              
            }); 
        }
    },

    methods: {
        
    },
    created() {
        const self = this;
        this.selectedMonth = moment().add(0,'months').format("YYYY-MM-01");

        new Promise(function(resolve) {
          if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
          resolve(1);
        }).then(function(result) { 
          if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.myflights[0]) self.$store.commit('updateFlightsFromDb', auth.currentUser.phoneNumber);
          return result;
        });
        
        
    }       

}


</script>