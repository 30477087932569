/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NewFpl from './views/newfpl.vue'
import PreviewFpl from './views/previewfpl.vue'
import DelayFpl from './views/delayfpl.vue'
import MyFpl from './views/myfpl.vue'
import LoginByPassword from './views/loginbypassword.vue'
import LoginByPhone from './views/loginbyphone.vue'
import Account from './views/account.vue'
import FlightTimetable from './views/flighttimetable.vue'
import Kiosk from './views/kiosk.vue'
import Briefing from './views/briefing.vue'
import Logbook from './views/logbook.vue'
import Payments from './views/payments.vue'
import SuccessPayment from './views/successpayment.vue'
import { auth } from './firebase'

const routes = [
  {
    path: '/',
    redirect: '/myfpl'
  },
  {
    path: '*',
    redirect: '/myfpl'
  },
  {
    path: '/loginbypassword',
    name: 'LoginByPassword',
    component: LoginByPassword
  },
  {
    path: '/loginbyphone',
    name: 'LoginByPhone',
    component: LoginByPhone
  },  
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/myfpl',
    name: 'MyFlightPlans',
    component: MyFpl,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newfpl',
    name: 'NewFlightPlan',
    component: NewFpl,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/previewfpl',
    name: 'PreviewFlightPlan',
    component: PreviewFpl,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/delayfpl',
    name: 'DelayFpl',
    component: DelayFpl,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/briefing',
    name: 'Briefing',
    component: Briefing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logbook',
    name: 'Logbook',
    component: Logbook,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/flighttimetable',
    name: 'FlightTimetable',
    component: FlightTimetable,
    meta: {
      requiresAuth: true
    }   
  },
  {
    path: '/payments',
    name: 'Online Payment',
    component: Payments,
    meta: {
      requiresAuth: true
    }   
  },
  {
    path: '/payments/success/:id',
    name: 'SuccessPayment',
    component: SuccessPayment,
    meta: {
      requiresAuth: true
    }   
  },
  {
    path: '/kiosk/:id',
    name: 'Kioskid',
    component: Kiosk   
  },
  {
    path: '/kiosk',
    name: 'Kiosk',
    component: Kiosk    
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueRouter)


router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) 
    { 
      next('/loginbyphone');
    }
    else {   
      next();
    }
});



export default router
