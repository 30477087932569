<template>

  <v-bottom-navigation    
    :value="activeBtnMenu"
    background-color="indigo darken-2" 
    centered
    dark
    app
    grow
  >
    
    <v-btn value="newfpl" aria-label="New" :to="{name:'NewFlightPlan'}" text height="auto">
      <span>New</span>
      <v-icon>{{icons.mdiPencil}}</v-icon>
    </v-btn>

    <v-btn value="myfpl" aria-label="Flights" :to="{name:'MyFlightPlans'}" text height="auto">
      <span>Flights</span>
      <v-icon>{{icons.mdiAirplane}}</v-icon>
    </v-btn>

    <v-btn value="briefing" aria-label="Briefing" :to="{name:'Briefing'}" text height="auto">
      <span>Briefing</span>
      <v-icon>{{icons.mdiInformationOutline}}</v-icon>
    </v-btn>
    
    <v-btn value="account" aria-label="Account" :to="{name:'Account'}" text height="auto">
      <span>Account</span>
      <v-badge
        :content="accountErrors"
        :value="accountErrors"
        color="red"
        overlap
        offset-x="0"
        offset-y="15"
      >
        <v-icon>{{icons.mdiAccount}}</v-icon>
      </v-badge>
    </v-btn>
    
  </v-bottom-navigation>

</template>

<script>

import { mapGetters } from 'vuex'
import {
    mdiAccount,
    mdiPencil,
    mdiAirplane,
    mdiInformationOutline
  } from '@mdi/js'

export default {
  name: 'Menu', 
  
  data() {
      return {
       icons: {
        mdiAccount,
        mdiPencil,
        mdiAirplane,
        mdiInformationOutline
        },
      };
    },

  props: {
    activeBtn: String
  },

  computed: {

    ...mapGetters({
      accountErrors: 'getAccountErrors'      
    }),
    activeBtnMenu() {      
      return this.activeBtn;
    },
    account() {
      return this.$store.state.account
    }   

  },

  methods: {
   
  }
};
</script>