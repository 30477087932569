<style scoped>
      .ad2hs-prompt {
        background-color: rgb(59, 134, 196); /* Blue */
        border: none;
        display: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;

        position: absolute;
        margin: 0 1rem 1rem;
        left: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 32px);
      }
      
      .ios-prompt {
        background-color: #fcfcfc;
        border: 1px solid #666;
        display: none;
        padding: 0.8rem 1rem 0 0.5rem;
        text-decoration: none;
        font-size: 16px;
        color: #555;

        position: absolute;
        margin: 0 auto 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 0;
      }
</style>

<template>

<v-main>
  <v-app>
      <v-container>
        <v-row
          align="start"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="indigo darken-2"
                dark
                flat
              >
                <v-toolbar-title dark><v-icon>mdi-airplane-takeoff</v-icon> .MD FPL</v-toolbar-title>
                <v-spacer />
                
              </v-toolbar>
              

              <v-card-text>
                <v-form ref="form" @keyup.native.enter="login"> 
                  
                  <v-text-field
                    clearable
                    label="Phone"
                    if="PhoneNumber"
                    v-model="phone"
                    prepend-icon="mdi-cellphone"
                    type="text"                    
                  />
                  <v-text-field
                    v-if="showcodeinput"
                    clearable
                    label="Code"                    
                    v-model="smscode"
                    prepend-icon="mdi-form-textbox-password"
                    type="text"
                    v-mask="'######'"
                    :rules="codeRules"
                  />
                  <v-btn
                    v-if="wrongcode"
                    depressed
                    color="error"
                    :style="{left: '50%', transform:'translateX(-50%)'}"
                  >
                    WRONG CODE
                  </v-btn>
                </v-form>
              </v-card-text>
              <v-card-actions v-if="showgetsms">
                <v-spacer />
                <v-btn color="indigo darken-2" block dark id="get-code-button" @click='submitPhoneNumberAuth'>Get SMS code</v-btn>
              </v-card-actions>
              <v-card-actions v-if="showcodeinput">
                <v-spacer />            
                <v-btn color="indigo darken-2" block dark id="sign-in-button" @click='submitPhoneNumberAuthCode'>Login</v-btn>
              </v-card-actions>
            </v-card>
            <div id="recaptcha-container"></div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row
          align="start"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
      <v-card small  outlined class="ma-0 pl-1">
        <v-card-text>
          <span class="orange--text text--darken-4 newlineclass">
            Disclaimer
          </span>
          <span class="newlineclass">
            The application is managed by AO "Aeroclubul din Chișinău". The user should provide only valid data. Any abuse or erroneous information will be reported.
          </span>
          <div align="center">
            <span class="newlineclass">© 2021 Aeroclubul din Chișinău</span>
            <span class="newlineclass">info@aeroclub.md</span>
            </div>
        </v-card-text>
      </v-card>
          </v-col>
        </v-row>
      </v-container>
</v-app>
      <button type="button" class="ad2hs-prompt">Install Web App</button>
      <div class="ios-prompt">
          <span style="color: rgb(187, 187, 187); float: right; margin-top: -14px; margin-right: -11px;">&times;</span>
          <img src="img/add2home.svg" style="float: left; height: 80px; width: auto; margin-top: -8px; margin-right: 1rem;">
          <p style="margin-top: -3px; line-height: 1.3rem;">To install MD FPL in your iPhone/iPad press <img src="img/share.svg" style="display: inline-block; margin-top: 4px; margin-bottom: -4px; height: 20px; width: auto;"> and then "Add to Home Screen"</p>
      </div>

</v-main>

</template>

<style scoped>
    .newlineclass { display: block; }
</style>

<script>
/* eslint-disable */
import firebase from 'firebase/app';
import { auth } from '../firebase';
import { firestore } from '../firebase'
import { VueMaskDirective } from 'v-mask'



export default {
  name: 'SignIn',
  components: {
  },
  directives: {
    mask: VueMaskDirective
  },

  data() {
    return {
     phone: "+373",
     smscode: "",
     showcodeinput: false,
     showgetsms: true,
     wrongcode: 0,
     codeRules: [
        v => !!v || 'Code is required',
        v => (v && v.length == 6) || 'Code must have 6 characters'
      ]
    };
  },  

  computed: {
    codeValidRules() {
        return () => (this.wrongcode == 0) || 'SMS code is not valid'
    },
  },

  methods: {
    submitPhoneNumberAuth: function() {
      const self = this;
      var phoneNumber = self.phone;
      var appVerifier = window.recaptchaVerifier;
      // auth.settings.appVerificationDisabledForTesting = true;
      auth
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          self.confirmationResult = confirmationResult;
          self.showcodeinput = true;
          self.showgetsms = false;
        })
        .catch(function(error) {
          console.log(error);
        });
      },

    onCountry(country) { 
      console.log(country);
    },

    onPhone(phone) { 
      console.log(phone); 
    },

    submitPhoneNumberAuthCode: function() {
      const self = this;
      fetch('https://europe-west3-aeroclub-fpl.cloudfunctions.net/getClientAddress')
        .then(x => x.json())
        .then(({ ip, userAgent }) => {
            var logs = {
              phone: self.phone,
              smscode: self.smscode,
              ip: ip,
              userAgent: userAgent,
              timestamp: new Date(),
              success: true,
              userid: ''
            };           
            self.confirmationResult
              .confirm(self.smscode)
              .then(function(result) {
                var user = result.user;
                logs.success = true;
                logs.userid = auth.currentUser.uid;
                firestore.collection('logs').add(logs).then(function() { });
                self.$router.replace('MyFlightPlans')
              })
              .catch(function(error) {
                self.wrongcode = 1;
                logs.success = false;
                firestore.collection('logs').add(logs).then(function() { });
                console.log(error);
              });              
        });
     
    },

  },

  mounted() {
    
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          'size': 'invisible',
          callback: function(response) {
            submitPhoneNumberAuth();
          }
        }
      );
    
    function addToHomeScreen() {
            let a2hsBtn = document.querySelector(".ad2hs-prompt");  // hide our user interface that shows our A2HS button
            a2hsBtn.style.display = 'none';  // Show the prompt
            deferredPrompt.prompt();  // Wait for the user to respond to the prompt
            deferredPrompt.userChoice
            .then(function(choiceResult){

            if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
            } else {
            console.log('User dismissed the A2HS prompt');
            }

            deferredPrompt = null;

        });}

        function showAddToHomeScreen() {

            let a2hsBtn = document.querySelector(".ad2hs-prompt");
            a2hsBtn.style.display = "block";
            a2hsBtn.addEventListener("click", addToHomeScreen);

        }

        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', function (e) {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;

            showAddToHomeScreen();
        });
        
        function showIosInstall() {
            let iosPrompt = document.querySelector(".ios-prompt");
            //console.log(iosPrompt)
            iosPrompt.style.display = "block";
            iosPrompt.addEventListener("click", () => {
            iosPrompt.style.display = "none";
            });
        }
        
        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test( userAgent );
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
            // this.setState({ showInstallMessage: true });
            showIosInstall();
        }



  },


};
</script>