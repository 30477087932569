
/* eslint-disable */

import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';
import { auth, firestore, functions } from './firebase'
import moment from 'moment'
import Flightplan from './domain/flightplan'

Vue.use(Vuex);
 
export default new Vuex.Store({
  
  state: {
    account: 
    {
        id: '',
        phone: '',
        firstname: '',
        lastname: '',
        email: '',
        licenseno: '',
        agree: '',
        aircraft: []        
    },
    availableAircrafts: [],
    availableAerodromes: [],
    flightplan: {
        fplAircraft: '',
        departureAerodrome: '',
        destinationAerodrome: '',
        departureTime: '',
        durationTime: '',
        arrivalTime: '',
        levelflight: '',
        typeofflight: '',
        personsonboard: '',
        routeflight: '',
        dateflight: ''
    },
    myflights: [],
    mypayments: []
  },

  getters: {
    getField,
    getAccountErrors: function(state) {
        var errors = 0;        
        for (const [key, value] of Object.entries(state.account)) {
            if ((value == '')||(key == '')) errors++;
          }        
        return errors;
    },
    getMyAccount: function(state) {
        return state.account;
    },
    getMyAircrafts: function(state) {
        return state.availableAircrafts.filter(element => state.account.aircraft.includes(element.id));
    },
    getAvailableAircrafts: function(state) {
        return state.availableAircrafts;
    },
    getSelectedAircraft: function(state) {
        return state.availableAircrafts.filter(element => state.flightplan.fplAircraft.includes(element.id)).find(e => !!e);
    },
    getDepartureAerodrome: function(state) {
        return state.availableAerodromes.filter(element => state.flightplan.departureAerodrome.includes(element.id)).find(e => !!e);
    },
    getDestinationAerodrome: function(state) {
        return state.availableAerodromes.filter(element => state.flightplan.destinationAerodrome.includes(element.id)).find(e => !!e);
    },
    getMyPayments: function(state) {
        return state.mypayments;
    },
    
  },

  mutations: {
    updateField,

    resetState(state) {
        state.token = null
        state.isLoggedIn = false
        localStorage.setItem('token', null)
        return state;
    },
    resetStateFlightplan(state) {
        state.flightplan = {
            fplAircraft: '',
            departureAerodrome: '',
            destinationAerodrome: '',
            departureTime: '',
            durationTime: '',
            arrivalTime: '',
            levelflight: '',
            typeofflight: '',
            personsonboard: '',            
            routeflight: '',
            dateflight: ''
        };
        return state;
    },
    updateAccountToDb(state) {
        firestore.collection("account").doc(state.account.id).set(state.account).then(function() {
            console.log("Account successfully saved!");
        });
        return state.account;
    },
    updateAccountFromDb(state, payload) {
        firestore.collection('account').doc(payload).get().then(function(accountRef) {
            if (accountRef.exists) {
                state.account = accountRef.data();
                console.log("Account updated");                
              } else {
                state.account = {
                  id: payload,
                  phone: payload,
                  firstname: '',
                  lastname: '',
                  email: '',
                  licenseno: '',
                  agree: '',
                  aircraft: [],
                  authorized: true
                };               
                console.log("State updated with defaults:");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    },
    updateAircraftsFromDb(state, payload) {
        firestore
            .collection('aircraft')            
            .orderBy('tailnumber')
            .get()
            .then(snap => {
                state.availableAircrafts = [];
                snap.forEach(doc => {
                    
                    state.availableAircrafts.push({
                        "id": doc.id, 
                        "tailnumber": doc.data().tailnumber,
                        "enabled": doc.data().enabled,
                        "speed": doc.data().speed,
                        "type": doc.data().type,
                        "equipment": doc.data().equipment,
                        "wake": doc.data().wake,
                        "tailnumberfull": doc.data().tailnumber + ' (' + doc.data().type + ')',
                        "owneremail": doc.data().owneremail
                    });
                    
                    //state.availableAircrafts.push( doc.data() );
                });
                console.log("Aircrafts updated");  
            });
        return state;
    },
    updateAerodromesFromDb(state, payload) {
        firestore
            .collection('aerodrome')
            .orderBy('order')
            .get()
            .then(snap => {
                state.availableAerodromes = [];
                snap.forEach(doc => {
                    
                    state.availableAerodromes.push({
                        "id": doc.id, 
                        "code": doc.data().code,
                        "name": doc.data().name,
                        "order": doc.data().order || 500,
                        "geo": doc.data().geo,
                        "email": doc.data().email || ""
                    });
                    
                   //state.availableAerodromes.push( doc.data() );
                });
                console.log("Aerodromes updated");  
                          
            });
        return state;
    },

    updateFlightsFromDb(state, payload) {
        let today = new Date(new Date().setHours(0,0,0,0)); 
        firestore
            .collection('flightplan')
            .where("meta.userid", "==", auth.currentUser.uid)
            .where("meta.showuntil", ">", today)
            .orderBy('meta.showuntil')
            .orderBy('meta.createddatetime', "desc")
            .get()
            .then(snap => {
                state.myflights = [];        
                snap.forEach(doc => {
                    state.myflights.push( doc.data() );                   
                });
                console.log("My flights updated");
                
            });
        return state;
    },
    
    updatePaymentsFromDb(state, payload) {
        let today = new Date(new Date().setHours(0,0,0,0)); 
        firestore
            .collection('payments')
            .where("Customer.Code", "==", auth.currentUser.phoneNumber)
            .orderBy('Registered', "desc")
            .limit(10)
            .get()
            .then(snap => {
                state.mypayments = [];        
                snap.forEach(doc => {
                    state.mypayments.push( doc.data() );            
                });
                console.log("My payments updated");
                
            });
        return state;
    },

    cancelFpl(state, id) {
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.status = 'cancelled';
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.cancelleddatetime = new Date();
        return state;
    },

    submitFpl(state, id) {
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.status = 'submitted';
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.submitteddatetime = new Date();
        return state;
    },

    delayFpl(state, payload) {
        var id = payload[0];
        var dalayeddatetime = payload[1];
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.status = 'delayed';
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.delayeddatetime = dalayeddatetime;
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].departureDateTime = dalayeddatetime;
        return state;
    },

    closeFpl(state, id) {
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.status = 'closed';
        state.myflights[state.myflights.findIndex((obj => obj.id == id))].meta.closeddatetime = new Date();
        return state;
    },

    


  },


})