<template>
<v-app>
    <v-main>
        <UpdatePwa />
        <router-view></router-view>

    </v-main>
</v-app>
</template>



<script>
import UpdatePwa from './components/updatepwa.vue'

export default {
    name: 'App',

    data: () => ({
        showUpdateUI: false
    }),

    components: {
        UpdatePwa
    },

    metaInfo() {
        return {
            title: ".MD Flight Plans",
            meta: [{
                    name: 'description',
                    content: 'Tool to submit flight plans over Moldova'
                },
                {
                    name: 'robots',
                    content: 'index,follow'
                }
            ]
        }
    },

    created() {

    },

    mounted() {
        

    }



};
</script>
