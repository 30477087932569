import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'


const firebaseConfig = {
    apiKey: "AIzaSyAjhk4ch6p0eZulWVs8NxGMxzzheTRxgag",
    authDomain: "aeroclub-fpl.firebaseapp.com",
    databaseURL: "https://aeroclub-fpl.firebaseio.com",
    projectId: "aeroclub-fpl",
    storageBucket: "aeroclub-fpl.appspot.com",
    messagingSenderId: "1018321629387",
    appId: "1:1018321629387:web:cbe495b1fa7dbc5b0b2b4e",
    measurementId: "G-YYL0GJM758"
  };

firebase.initializeApp(firebaseConfig)

// utils
const firestore = firebase.firestore()
const auth = firebase.auth()
var functions = firebase.app().functions('europe-west3');


// export utils/refs
export {
  firestore,
  auth,
  functions
}