
export default class Timetableitem {

    constructor(id, tailnumber, type, departureAerodromeId, departureAerodromeCode, departureAerodromeName, destinationAerodromeId, destinationAerodromeCode, destinationAerodromeName, 
            departureDateTime, arrivalDateTime, durationMin, pilotname, updatedDateTime, userid, status) {
      this.id = id;
      this.tailnumber = tailnumber;
      this.type = type;
      this.departureAerodromeId = departureAerodromeId;
      this.departureAerodromeCode = departureAerodromeCode;
      this.departureAerodromeName = departureAerodromeName;
      this.destinationAerodromeId = destinationAerodromeId;
      this.destinationAerodromeCode = destinationAerodromeCode;
      this.destinationAerodromeName = destinationAerodromeName;
      this.departureDateTime = departureDateTime;
      this.arrivalDateTime = arrivalDateTime;
      this.durationMin = durationMin;
      this.pilotname = pilotname;
      this.updatedDateTime = updatedDateTime;
      this.userid = userid;
      this.status = status;
    }
      
  
}