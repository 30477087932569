
<template>

 <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="indigo darken-2"
                dark
                flat
              >
                <v-toolbar-title dark><v-icon>mdi-airplane-takeoff</v-icon> .MD FPL</v-toolbar-title>
                <v-spacer />
                
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" @keyup.native.enter="login"> 
                  <v-text-field
                    clearable                
                    label="Email"
                    v-model="email"
                    prepend-icon="mdi-account"
                    type="text"
                    :rules="emailRules"
                  />

                  <v-text-field
                    clearable  
                    id="password"
                    label="Password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  />
                
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="indigo darken-2" block dark @click='login'>Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

</template>


<script>
  import { auth } from '../firebase';

  export default {
    name: 'LoginByPassword',

    data() {
      return {
        email: '',
        password: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      }
    },    
   
    methods: {
      login: function() {
        auth.signInWithEmailAndPassword(this.email, this.password).then(
          () => {
            this.$router.replace('myfpl')
          },
          (err) => {
            alert('Oops. ' + err.message)
          }
        );
      }
    }
    
  }
</script>