<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
   <v-row align="center" class="pa-0">
          <v-col cols="auto">
          <v-icon color="white" class="mr-3">{{ icons.mdiAirplane }}</v-icon>
           Flight Timetable
          </v-col>
          <v-spacer class="d-none d-sm-flex" />
          <v-col class="d-none d-sm-flex">                     
          <v-select  v-model="selectedAerodrome" :items="availableAerodromes" item-text="name" item-value="id" type="text" 
            filled outlined dense hide-details dark ></v-select>
          </v-col>
          <v-col class="d-none d-sm-flex">
          <v-select  v-model="selectedDate" :items="availableDates" item-text="text" item-value="value" 
            filled outlined dense hide-details dark ></v-select>
          </v-col>
   </v-row>
 </v-app-bar> 
<v-main class="pa-0">
  <v-container fluid class="pa-5">
   <v-row class="pa-0 green darken-4" align="center">
       <v-col cols="auto" class="pa-4">
          <v-icon dark>{{ icons.mdiAirplaneTakeoff }}</v-icon>
       </v-col>
       <v-col class="pa-0">       
        <v-data-table   
          dense
          disable-pagination
          disable-filtering
          disable-sort
          :headers="headersdepartures"
          :items="departureTimetable"
          :item-class="itemrowclass"
          item-key="name"
          hide-default-footer
          class="rounded-0"
        ></v-data-table>
       </v-col>
    </v-row>
  </v-container>
  <v-container fluid class="pa-5">
   <v-row class="pa-0 orange darken-4" align="center">
       <v-col cols="auto" class="pa-4">
          <v-icon dark>{{ icons.mdiAirplaneLanding }}</v-icon>
       </v-col>
       <v-col class="pa-0">       
        <v-data-table 
          dense 
          disable-pagination
          disable-filtering
          disable-sort
          :headers="headersarrivals"
          :items="arrivalTimetable"
          :item-class="itemrowclass"
          item-key="name"          
          hide-default-footer
          class="rounded-0"
        ></v-data-table>
       </v-col>
    </v-row>
  </v-container>
</v-main>
  <Menu v-bind:activeBtn.sync="activeBtn" />

</v-app>
</template>

<style>
    .row-submitted-1 td {        
        background-color: #FFFFFF;
    }
    .row-delayed-1 td {        
        background-color: #BDBDBD;
    }
    .row-closed-1 td {        
        background-color: #81C784;
    }
</style>

<script>
  /* eslint-disabsle */
  import { auth, firestore } from '../firebase'
  import { mapFields } from 'vuex-map-fields';
  import Menu from '../components/menu.vue'
  import moment from 'moment'
  import {
    mdiAirplane,
    mdiAirplaneLanding,
    mdiAirplaneTakeoff
  } from '@mdi/js'

  export default {
    name: 'FlightTimetable',

    components: {
      Menu
    },

    data: () => (
      { 
        icons: {
          mdiAirplane,
          mdiAirplaneLanding,
          mdiAirplaneTakeoff
        },
        departureTimetable: [],
        arrivalTimetable: [],
        activeBtn: 'account',
        valid: true,        
        headersdepartures: [
          { text: 'TIME',  value: 'time', sortable:false, class: 'white--text green darken-4 text-button', width: '8%' },
          { text: 'TO', value: 'destination', sortable:false, class: 'white--text green darken-4 text-button', width: '32%' },
          { text: 'AIRCRAFT', value: 'aircraft', sortable:false, class: 'white--text green darken-4 text-button', width: '12%' },
          { text: 'TYPE', value: 'type', sortable:false, class: 'white--text green darken-4 text-button', width: '12%' },
          { text: 'PILOT', value:'pilot', sortable:false, class: 'white--text green darken-4 text-button', width: '36%' }
        ],
        headersarrivals: [
          { text: 'TIME',  value: 'time', sortable:false, class: 'white--text orange darken-4 text-button', width: '8%'},
          { text: 'FROM', value: 'departure', sortable:false, class: 'white--text orange darken-4 text-button', width: '32%' },
          { text: 'AIRCRAFT', value: 'aircraft', sortable:false, class: 'white--text orange darken-4 text-button', width: '12%' },
          { text: 'TYPE', value: 'type', sortable:false, class: 'white--text orange darken-4 text-button', width: '12%' },
          { text: 'PILOT', value:'pilot', sortable:false, class: 'white--text orange darken-4 text-button', width: '36%' }
        ],        
        selectedAerodrome: '',
        availableDates: [
          { text: moment().subtract(2, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().subtract(2, 'day').startOf("day").format('YYYY-MM-DD') },
          { text: moment().subtract(1, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().subtract(1, 'day').startOf("day").format('YYYY-MM-DD') },
          { text: moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD'), value: moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD') },
          { text: moment().add(1, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().add(1, 'day').startOf("day").format('YYYY-MM-DD') },
          { text: moment().add(2, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().add(2, 'day').startOf("day").format('YYYY-MM-DD') },
        ],
        selectedDate: '',
        newaerodromePlusDate: ''
      }),

    methods: {
      itemrowclass(item) {
        switch(item.status) {
          case 'submitted':
            return 'row-submitted-1'
          case 'delayed':
            return 'row-delayed-1'
          case 'closed':
            return 'row-closed-1'
          default:
            return 'row-submitted-1'
        }
      }

    },
     
    watch: {
      aerodromePlusDate: function(newaerodromePlusDate) {
        const self = this;
        firestore.collection("timetable")
          .where('departureAerodromeId', "==", newaerodromePlusDate[0])
          .where('status', 'in', ['submitted', 'delayed', 'closed'])
          .where("departureDateTime", ">=", moment(newaerodromePlusDate[1]).toDate())
          .where("departureDateTime", "<", moment(newaerodromePlusDate[1]).add(1,'day').toDate())
          .orderBy('departureDateTime' )
          .onSnapshot(function(querySnapshot) {
            self.departureTimetable = [];
            querySnapshot.forEach(function(doc) {
              self.departureTimetable.push({
                time: moment(moment.unix(doc.data().departureDateTime.seconds)).format("HH:mm"),
                destination: doc.data().destinationAerodromeName,
                aircraft: doc.data().tailnumber,
                type: doc.data().type,
                pilot: doc.data().pilotname.toUpperCase(),
                status: doc.data().status
              });
            })
          });


          firestore.collection("timetable")
          .where('destinationAerodromeId', "==", newaerodromePlusDate[0])
          .where('status', 'in', ['submitted', 'delayed', 'closed'])
          .where("arrivalDateTime", ">=", moment(newaerodromePlusDate[1]).toDate())
          .where("arrivalDateTime", "<", moment(newaerodromePlusDate[1]).add(1,'day').toDate())
          .orderBy('arrivalDateTime' )
          .onSnapshot(function(querySnapshot) {
            self.arrivalTimetable = [];
            querySnapshot.forEach(function(doc) {
              self.arrivalTimetable.push({
                time: moment(moment.unix(doc.data().arrivalDateTime.seconds)).format("HH:mm"),
                departure: doc.data().departureAerodromeName,
                aircraft: doc.data().tailnumber,
                type: doc.data().type,
                pilot: doc.data().pilotname.toUpperCase(),
                status: doc.data().status
              });
            })
          });
      
      },


    }, 

    

    computed: {
        ...mapFields({
            availableAerodromes: 'availableAerodromes',
            }),
        aerodromePlusDate() {
          return [this.selectedAerodrome, this.selectedDate]
        }
    },

    mounted() {
        const self = this;                
        
        new Promise(function(resolve) {
          if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
          resolve(1);
        }).then(function(result) { 
          if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber)
          return result;
        }).then(function(result) { 
          if (!self.$store.state.myflights[0]) self.$store.commit('updateFlightsFromDb', auth.currentUser.phoneNumber);
          return result;
        });
        
        self.selectedAerodrome = '7FMEKOl5Rwj0AteTydUr';
        self.selectedDate = moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD');

        


    }   
    
  }
</script>
