<template>
<v-app>
    <v-app-bar color="indigo darken-2 white--text text-h6" app>
        <v-icon color="white" class="mr-3">{{ icons.mdiPencil }}</v-icon>
        New Flight Plan
    </v-app-bar>
    <v-main class="pa-0">
        <v-container fluid class="pa-4">
            <v-layout wrap>
                <v-flex class="">
                    <v-form ref="form" v-model="valid" >
                        <v-select v-model="fplAircraft" :items="myAircrafts" item-text="tailnumberfull" item-value="id" label="Aircraft identification" chips hint="Select the aircraft" required :rules="fplAircraftRules"></v-select>
                        <v-select v-model="departureAerodrome" :items="availableAerodromes" item-text="name" item-value="id" label="Departure aerodrome" chips dense hint="Select the departure aerodrome" required :rules="departureAerodromeRules"></v-select>
                        <v-select v-model="destinationAerodrome" :items="availableAerodromes" item-text="name" item-value="id" label="Destination aerodrome" chips dense hint="Select the destination aerodrome" required :rules="destinationAerodromeRules"></v-select>
                        <v-menu v-model="showDateFlightPicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="dateflight" label="Day of flight" persistent-hint readonly v-on="on" required :rules="dateFlightRules" />
                            </template>
                            <v-date-picker v-model="dateflight" light no-title show-adjacent-months :min="dateflightmin" :max="dateflightmax" @input="showDateFlightPicker = false" />
                        </v-menu>
                        <v-menu v-model="showDepTimePicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="departureTime" label="Departure time (UTC)" persistent-hint readonly v-on="on" required :rules="departureTimeRules" />
                            </template>
                            <v-time-picker v-model="departureTime" format="24hr" :allowed-minutes="allowedMinutes" @input="showDepTimePicker = false" />
                        </v-menu> 
                        <v-menu v-model="showDurationTimePicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="durationTime" label="Flight duration" persistent-hint readonly v-on="on" required :rules="durationTimeRules" />
                            </template>
                            <v-time-picker v-model="durationTime" format="24hr" :allowed-minutes="allowedDurationMinutes" :allowed-hours="allowedDurationHours" @input="showDurationTimePicker = false" />
                        </v-menu>
                        <v-select v-model="levelflight" :items="availableLevelFlight" label="Altitude / level for flight" chips dense hint="Select the altitude / level for flight" required :rules="levelflightRules"></v-select>
                        <v-select v-model="typeofflight" :items="availableTypeOfFlight" label="Type of flight" chips dense hint="Select the type of flight" required :rules="typeofflightRules"></v-select>
                        <v-select v-model="personsonboard" :items="availablePersonsOnBoard" label="Persons on-board" chips dense hint="Select number of persons on-board" required :rules="personsonboardRules"></v-select>
                        <v-textarea v-model="routeflight" clearable clear-icon="mdi-close-circle" label="Route" rows="2"></v-textarea>

                        <v-layout row child-flex justify-center align-center wrap pa-4>
                            <v-btn v-if="showpreview" :disabled="!valid" color="success" class="white--text" :to="{name:'PreviewFlightPlan'}" :style="{left: '50%', transform:'translateX(-50%)'}">
                                PREVIEW
                                <v-icon right dark> mdi-eye </v-icon>
                            </v-btn>
                        </v-layout>

                    </v-form>
                </v-flex>
            </v-layout>
        </v-container>
    </v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>

<script>
import Menu from '../components/menu.vue'
import {
    mapFields
} from 'vuex-map-fields';
import {
    auth
} from '../firebase'
import moment from 'moment'
import {
    mdiPencil
} from '@mdi/js'

export default {
    name: 'NewFlightPlan',

    components: {
        Menu
    },

    data: () => ({
        icons: {
            mdiPencil
        },
        activeBtn: 'newfpl',
        valid: true,
        showDepTimePicker: false,
        showDurationTimePicker: false,
        allowedMinutes: m => m % 5 === 0,
        allowedDurationMinutes: m => m % 5 === 0,
        allowedDurationHours: [0, 1, 2, 3, 4, 5],
        availableLevelFlight: ['VFR', 'A015', 'A025', 'A035', 'A045', 'F055', 'F065', 'F075', 'F085', 'F095', 'F105', 'F115'],
        availablePersonsOnBoard: ['1', '2', '3', '4', '5', '6', '8', '10', '12', '14'],
        availableTypeOfFlight: ['Private flight', 'Cost-sharing flight', 'Introductory flight', 'Training organization flight', 'Parachute jumping flight', 'Technical flight', 'Reposition flight'],
        fplAircraftRules: [v => !!v || 'Aircraft is required'],
        departureAerodromeRules: [v => !!v || 'Departure aerodrome is required'],
        departureTimeRules: [v => !!v || 'Departure time is required'],
        durationTimeRules: [v => !!v || 'Duration is required'],
        destinationAerodromeRules: [v => !!v || 'Destination aerodrome is required'],
        levelflightRules: [v => !!v || 'Altitude / flight level is required'],
        typeofflightRules: [v => !!v || 'Type of flight is required'],
        personsonboardRules: [v => !!v || 'Number of persons on-board is required'],
        dateflightmin: '',
        dateflightmax: '',
        showDateFlightPicker: false,
        dateFlightRules: [v => !!v || 'Day of flight is required']
    }),

    watch: {
        routeflight: function (newValue) {
            //console.log(newValue);
            if (this.routeflight != null) this.routeflight = this.capitalizeString(newValue.replace(/[^\w ]/, ''));
        },
    },

    computed: {
        ...mapFields({
            selectedAircrafts: 'account.aircraft',
            availableAerodromes: 'availableAerodromes',
            fplAircraft: 'flightplan.fplAircraft',
            departureAerodrome: 'flightplan.departureAerodrome',
            destinationAerodrome: 'flightplan.destinationAerodrome',
            departureTime: 'flightplan.departureTime',
            durationTime: 'flightplan.durationTime',
            levelflight: 'flightplan.levelflight',
            typeofflight: 'flightplan.typeofflight',
            personsonboard: 'flightplan.personsonboard',
            routeflight: 'flightplan.routeflight',
            dateflight: 'flightplan.dateflight'
        }),
        myAircrafts() {
            var myacfts = this.$store.getters.getMyAircrafts;
            return myacfts.filter(element => element.enabled == true);
        },
        showpreview() {
            var show = false;
            if ((this.$store.state.account.authorized == true) && (this.$store.state.account.aircraft.length > 0)) show = true;
            return show;
        }
    },

    methods: {
        capitalizeString: (str) => {
            return str.toUpperCase();
        }
    },
    created() {
        const self = this;
        self.departureTime = moment.utc().add(15, 'minutes').minutes(Math.floor(moment.utc().add(15, 'minutes').minutes() / 15) * 15).format('HH:mm');
        self.dateflight = moment.utc().format("YYYY-MM-DD");
        self.dateflightmin = moment.utc().format("YYYY-MM-DD");
        self.dateflightmax = moment.utc().add(5, 'days').format("YYYY-MM-DD");

        new Promise(function (resolve) {
            if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
            resolve(1);
        }).then(function (result) {
            if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', auth.currentUser.phoneNumber)
            return result;
        }).then(function (result) {
            if (!self.$store.state.availableAircrafts[0]) self.$store.commit('updateAircraftsFromDb', auth.currentUser.phoneNumber)
            return result;
        });

        
    }
}
</script>
