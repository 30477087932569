
export default class Aircraft {

  constructor(id, tailnumber, type, speed, equipment, wake, owneremail) {
    this.id = id;
    this.tailnumber = tailnumber;
    this.type = type;
    this.speed = speed || 100;
    this.equipment = equipment || "Y/L";
    this.wake = wake || 'L';
    this.owneremail = owneremail || '';
  }



}