import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './vuetify'
import { auth } from './firebase'
import wb from "./registerServiceWorker";
import VueMeta from 'vue-meta'


Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;
Vue.use(VueMeta);

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      store,
      router,      
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})