<template>
<v-app>
 
<v-main class="pa-0">
  <v-container fluid class="pa-2">

<v-row class="pa-0 ma-0 mb-3">
    <v-col class="text-h3 grey darken-3 font-weight-bold pl-5" justify="left" align="left">
       {{ selectedAerodromeName }}
    </v-col>    
    <v-col class="text-h3 grey darken-3 font-weight-bold" justify="right" align="right">
        <digital-clock :blink="true" :displaySeconds="true" />
    </v-col>

</v-row>



<v-row>
    <v-col cols="6">

        <v-row class="pa-0 ma-0 orange lighten-1" justify-content="space-between">
            <v-col class="ml-3 text-h3 font-weight-bold black--text"> 
                DEPARTURES
            </v-col>
            <v-spacer cols="auto" />
            <v-col class="pa-4" align="right">
                <v-icon x-large color="black">{{ icons.mdiAirplaneTakeoff }}</v-icon>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 orange lighten-1" >
            <v-col cols="12" class="pa-0">       
                <v-data-table   
                disable-pagination
                disable-filtering
                disable-sort                   
                :headers="headersdepartures"
                :items="departureTimetableFiltered"
                item-key="name"
                :item-class="itemrowclass"
                hide-default-footer
                class="rounded-0"
                ></v-data-table>
            </v-col>
        </v-row>

    </v-col>

    <v-col cols="6">

        <v-row class="pa-0 ma-0 blue darken-4" justify-content="space-between">
            <v-col class="ml-3 text-h3 font-weight-bold white--text"> 
                ARRIVALS
            </v-col>
            <v-spacer cols="auto" />
            <v-col class="pa-4" align="right">
                <v-icon x-large color="white">{{ icons.mdiAirplaneLanding }}</v-icon>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 blue-grey darken-3" >
            <v-col cols="12" class="pa-0">       
                <v-data-table
                disable-pagination
                disable-filtering
                disable-sort               
                :headers="headersarrivals"
                :items="arrivalTimetableFiltered"
                item-key="name"
                :item-class="itemrowclass"
                hide-default-footer
                class="rounded-0"
                ></v-data-table>
            </v-col>
        </v-row>

    </v-col>

</v-row>


  </v-container>


</v-main>
</v-app>
</template>

<style>
    .row-submitted td {        
        font-size: 18px !important;
    }
    .row-delayed td {        
        font-size: 18px !important;
        background-color: #424242;
    }
    .row-closed td {        
        font-size: 18px !important;
        background-color: #1B5E20;
    }
</style>

<script>
  /* eslint-disable */
  import { auth, firestore } from '../firebase'
  import { mapFields } from 'vuex-map-fields';
  import moment from 'moment'
  import {
    mdiAirplane,
    mdiAirplaneLanding,
    mdiAirplaneTakeoff
  } from '@mdi/js'
  import DigitalClock from "vue-digital-clock";


  export default {
    name: 'Kiosk',

    components: {
        DigitalClock
    },

    data: () => (
      { 
        icons: {
          mdiAirplane,
          mdiAirplaneLanding,
          mdiAirplaneTakeoff
        },
        departureTimetable: [],
        arrivalTimetable: [],
        activeBtn: 'flighttimetable',
        valid: true,        
        headersdepartures: [
          { text: 'Time',  value: 'time', sortable:false, class: 'orange--text text--lighten-1 text-h5 font-weight-bold ', width: '8%' },
          { text: 'To', value: 'destination', sortable:false, class: 'orange--text text--lighten-1 text-h5 font-weight-bold', width: '37%' },
          { text: 'Aircraft', value: 'aircraft', sortable:false, class: 'orange--text text--lighten-1 text-h5 font-weight-bold', width: '10%' },
          { text: 'Type', value: 'type', sortable:false, class: 'orange--text text--lighten-1 text-h5 font-weight-bold', width: '10%' },
          { text: 'Pilot', value:'pilot', sortable:false, class: 'orange--text text--lighten-1 text-h5 font-weight-bold', width: '35%' }
        ],
        headersarrivals: [
          { text: 'Time',  value: 'time', sortable:false, class: 'blue--text text-darken-4 text-h5 font-weight-bold', width: '10%'},
          { text: 'From', value: 'departure', sortable:false, class: 'blue--text text-darken-4 text-h5 font-weight-bold', width: '37%' },
          { text: 'Aircraft', value: 'aircraft', sortable:false, class: 'blue--text text-darken-4 text-h5 font-weight-bold', width: '10%' },
          { text: 'Type', value: 'type', sortable:false, class: 'blue--text text-darken-4 text-h5 font-weight-bold', width: '10%' },
          { text: 'Pilot', value:'pilot', sortable:false, class: 'blue--text text-darken-4 text-h5 font-weight-bold', width: '35%' }
        ],        
        selectedAerodrome: '',
        availableDates: [
          { text: moment().subtract(2, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().subtract(2, 'day').startOf("day").format('YYYY-MM-DD') },
          { text: moment().subtract(1, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().subtract(1, 'day').startOf("day").format('YYYY-MM-DD') },
          { text: moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD'), value: moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD') },
          { text: moment().add(1, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().add(1, 'day').startOf("day").format('YYYY-MM-DD') },
          { text: moment().add(2, 'day').startOf("day").format('YYYY-MM-DD'), value: moment().add(2, 'day').startOf("day").format('YYYY-MM-DD') },
        ],
        newaerodromePlusDate: ''
      }),

    methods: {
      itemrowclass(item) {
        switch(item.status) {
          case 'submitted':
            return 'row-submitted'
          case 'delayed':
            return 'row-delayed'
          case 'closed':
            return 'row-closed'
          default:
            return 'row-submitted'
        }
      },

      updateSelectedDate() {
          return moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD');
        }

    },
     
    watch: {
      aerodromePlusDate: function(newaerodromePlusDate) {
        const self = this;

        firestore.collection("timetable")
        .where('departureAerodromeId', "==", newaerodromePlusDate[0])
        .where('status', 'in', ['submitted', 'delayed', 'closed'])
        .where("departureDateTime", ">=", moment(newaerodromePlusDate[1]).toDate())
        .where("departureDateTime", "<", moment(newaerodromePlusDate[1]).add(1,'day').toDate())
        .orderBy('departureDateTime' )
        .onSnapshot(function(querySnapshot) {
          self.departureTimetable = [];
          querySnapshot.forEach(function(doc) {
            self.departureTimetable.push({
              time: moment(moment.unix(doc.data().departureDateTime.seconds)).format("HH:mm"),
              destination: doc.data().destinationAerodromeName,
              aircraft: doc.data().tailnumber,
              type: doc.data().type,
              pilot: doc.data().pilotname.toUpperCase(),
              status: doc.data().status
            });
          })
        });


        firestore.collection("timetable")
        .where('destinationAerodromeId', "==", newaerodromePlusDate[0])
        .where('status', 'in', ['submitted', 'delayed', 'closed'])
        .where("arrivalDateTime", ">=", moment(newaerodromePlusDate[1]).toDate())
        .where("arrivalDateTime", "<", moment(newaerodromePlusDate[1]).add(1,'day').toDate())
        .orderBy('arrivalDateTime' )
        .onSnapshot(function(querySnapshot) {
          self.arrivalTimetable = [];
          querySnapshot.forEach(function(doc) {
            self.arrivalTimetable.push({
              time: moment(moment.unix(doc.data().arrivalDateTime.seconds)).format("HH:mm"),
              departure: doc.data().departureAerodromeName,
              aircraft: doc.data().tailnumber,
              type: doc.data().type,
              pilot: doc.data().pilotname.toUpperCase(),
              status: doc.data().status
            });
          })
        });


      },


    }, 

    

    computed: {
        ...mapFields({
            availableAerodromes: 'availableAerodromes',
            }),
        aerodromePlusDate() {
          return [this.selectedAerodrome, this.selectedDate]
        },
        selectedDate() {
          return this.updateSelectedDate();
        },
        
        selectedAerodromeName() {
          var aerodrome = this.$store.state.availableAerodromes.filter(element => this.selectedAerodrome.includes(element.id)).find(e => !!e)
          if (typeof aerodrome != "undefined") {
            return aerodrome.name;
          } else {
            return ''
          }
        },

        arrivalTimetableFiltered() {
          if (this.arrivalTimetable.length > 10) {
            return this.arrivalTimetable
          } else {
            return this.arrivalTimetable.slice(0, 10);
          }          
        },

        departureTimetableFiltered() {
          if (this.departureTimetable.length > 10) {
            return this.departureTimetable
          } else {
            return this.departureTimetable.slice(0, 10);
          }          
        }
        
    },

    mounted() {
        document.documentElement.style.overflow = 'hidden'
        const self = this;
        self.$vuetify.theme.dark = true;        
        
        self.selectedAerodrome = self.$route.params.id ?? '7FMEKOl5Rwj0AteTydUr';
        if (!self.$store.state.availableAerodromes[0]) self.$store.commit('updateAerodromesFromDb', '')

        setInterval(self.updateSelectedDate.bind(self), 60000)

    }   
    
  }
</script>
