<template>
<v-app>
 <v-app-bar color="indigo darken-2 white--text text-h6" app>
          <v-row align="center" class="pa-0">
          <v-col cols="auto">
          <v-icon color="white" class="mr-3">{{ icons.mdiCreditCardOutline }}</v-icon>
           Online payments
          </v-col>
          <v-spacer class="d-none d-sm-flex" />          
          <v-col class="d-none d-sm-flex">
          
          </v-col>
   </v-row>
 </v-app-bar> 
<v-main class="pa-0">

<v-container fluid class="pa-4">
   <v-layout wrap>
       <v-flex class="">
         <v-card class="d-flex justify-center pa-0" outlined>
               <v-card-text class="text-center">
       <v-form ref="form" v-model="valid">
            <v-select v-model="selectedService" :items="availableServices" item-text="name" item-value="code" label="Service" chips hint="Select the service" required :rules="servicesRules"></v-select>
            <v-text-field label="Amount" v-model="amount" value="" suffix="LEI" type="number" :rules="amountRules"></v-text-field>
            <v-text-field readonly label="First Name" v-model="account.firstname" :rules="firstnameRules"></v-text-field>
            <v-text-field readonly label="Last Name" v-model="account.lastname" :rules="lastnameRules"></v-text-field>
            <v-text-field readonly label="Phone number" v-model="account.phone" :rules="phoneRules"></v-text-field>
            <v-text-field readonly label="Email" v-model="account.email" :rules="emailRules"></v-text-field>
            <v-text-field label="Country" v-model="country" value="" type="text" :rules="countryRules"></v-text-field>
            <v-text-field label="City" v-model="city" value="" type="text" :rules="cityRules"></v-text-field>
            <v-text-field label="Address" v-model="address" value="" type="text" :rules="addressRules"></v-text-field>
            <v-checkbox v-model="agreee" color="red darken-3" value="" required :rules="agreeRules">
              <template v-slot:label>
                <div> I have read and agreee with 
                  <a target="_blank" href="/docs/Aeroclub_ConditiiComerciale.pdf" @click.stop v-on="on">
                    Terms & Conditions 
                  </a> 
                </div>
              </template>
            </v-checkbox>
            
           

            <v-layout row child-flex justify-center align-center pa-4>
              <v-spacer />
                <v-btn :disabled="!valid" color="success" class="white--text" @click="makePayment(account, address, city, country, amount, selectedService)">
                  PAY ONLINE
                  <v-icon right dark> {{ icons.mdiContentSave }} </v-icon>
                </v-btn>
              <v-spacer />
              </v-layout>
               <v-layout row child-flex justify-center align-center pa-4>
              <v-card :elevation="0">
                A.O. "Aeroclubul din Chisinau" <br>
                str. Columna 170, Chisinau, R. Moldova <br>
                +37368663333 / info@aeroclub.md <br>
                <v-btn outlined x-small color="teal" class="white--text" href="/docs/Aeroclub_ConditiiComerciale.pdf" target="_blank">Terms & Conditions</v-btn><br>
                <v-btn outlined x-small color="teal" class="white--text" href="/docs/Aeroclub_PoliticaConfidentialitate.pdf" target="_blank">Confidentiality policy</v-btn><br>
                <v-container class="fill-height" fluid >
                  <v-row>
                    <v-col cols="4"><v-img src="https://paynet.md/content/images/paynet-top-logo-grey.png" height="15" contain /></v-col>
                    <v-col cols="4"><v-img src="https://paynet.md/content/images/logos/cardvisalogomin.png" height="15" contain /></v-col>
                    <v-col cols="4"><v-img src="https://paynet.md/content/images/logos/cardmastercardlogomin.png" height="15" contain  /></v-col>                
                  </v-row>
                </v-container>
              </v-card>
            </v-layout>              

        </v-form>

        <v-form style="display: none" action="https://paynet.md/acquiring/getecom" method="POST" ref="paynetform">
          <v-text-field type="hidden" name="operation" v-model="operation"> </v-text-field>
          <v-text-field type="hidden" name="Signature" v-model="Signature"> </v-text-field>
          <v-text-field type="hidden" name="LinkUrlCancel" v-model="LinkUrlCancel"> </v-text-field>
          <v-text-field type="hidden" name="LinkUrlSucces" v-model="LinkUrlSuccesComputed"> </v-text-field>
          <v-text-field type="hidden" name="Lang" v-model="Lang"> </v-text-field>
        </v-form>
        </v-card-text>               
           </v-card>
       </v-flex>
   </v-layout>
</v-container>

  <v-container fluid class="pa-5">
   <v-row class="pa-0 green darken-4" align="center">
       <v-col class="pa-0">
        <v-data-table
          dense
          disable-pagination	
          disable-sort
          :headers="headerspayments"
          :items="paymentlog"
          item-key="name"
          hide-default-footer
          class="rounded-0"
        ></v-data-table>
       </v-col>
    </v-row>
  </v-container>

</v-main>

    <Menu v-bind:activeBtn.sync="activeBtn" />
</v-app>
</template>


<script>
/* eslint-disable */
import Menu from '../components/menu.vue'
import { mdiCreditCardOutline } from '@mdi/js'
import moment from 'moment'
import { auth, firestore } from '../firebase'
import { functions } from "../firebase";

export default {
    name: 'Payments',

    components: {
        Menu
    },

    data: () => ({ 
        icons: {
          mdiCreditCardOutline
        },
        activeBtn: 'account',
        valid: true,
        dialog: false,
        selectedMonth: '',
       headerspayments: [
          { text: 'Date',  value: 'date', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Service', value: 'service', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Amount', value:'amount', sortable:false, class: 'white--text pink darken-2 text-caption' },
          { text: 'Status', value:'status', sortable:false, class: 'white--text pink darken-2 text-caption' }
        ],
        availableServices: [
          { name: 'Aircraft takeoff and landing', code: 'aero01' },
          { name: 'Aircraft storage at aerodrome', code: 'aero02' },
          { name: 'Annual membership payment', code: 'aero03' },
          { name: 'Donation for development', code: 'aero04' }
        ],
        selectedService: '',
        servicesRules: [v => !!v || 'Service is required'],
        amount: '',
        amountRules: [ v  => { if (!v.trim()) return false; if (!isNaN(parseFloat(v)) && v >= 10 && v <= 10000) return true; return 'Amount has to be between 10 and 10000 LEI'; } ],
        country: '',
        countryRules: [
            v => !!v || 'Country is required',
            v => (v && v.length >= 4) || 'Minimum length is 5 characters',
        ],
        city: '',
        cityRules: [
            v => !!v || 'City is required',
            v => (v && v.length >= 4) || 'Minimum length is 5 characters',
        ],
        address: '',
        addressRules: [
            v => !!v || 'Address is required',
            v => (v && v.length >= 4) || 'Minimum length is 8 characters',
        ],
        firstnameRules: [
            v => !!v || 'First name is required',
            v => (v && v.length >= 4) || 'Minimum length is 3 characters',
        ],
        lastnameRules: [
            v => !!v || 'Last name is required',
            v => (v && v.length >= 4) || 'Minimum length is 3 characters',
        ],
        phoneRules: [
            v => !!v || 'Phone is required',
            v => (v && v.length >= 4) || 'Minimum length is 7 characters',
        ],
        emailRules: [
            v => !!v || 'Email is required',
            v => (v && v.length >= 4) || 'Minimum length is 5 characters',
        ],
        agreee: '',
        agreeRules: [
            v => !!v || 'Agree is required'
        ],
        on: '',
        operation: '',
        Signature: '',
        LinkUrlCancel: "https://fpl.aeroclub.md/payments",
        LinkUrlSucces: "https://fpl.aeroclub.md/payments/success",
        Lang: "en-US"
    }),

    computed: {
        
        account() {
          try {
            const self = this;
            return self.$store.getters.getMyAccount;
          }
          catch {
            return false;
          }
        },
        mypayments() {
            try {
            const self = this;
            return self.$store.getters.getMyPayments;
          }
          catch {
            return false;
          }
        },
        paymentlog: {
            get() {
                const self = this;
                const log = [];
                self.mypayments.forEach(function (item, index) {
                    var statustext = '';
                    switch(item.Status) 
                    { 
                      case 1: statustext = "Registered"; break;
                      case 2: statustext = "In progress"; break;
                      case 3: statustext = "In progress"; break;
                      case 4: statustext = "Completed"; break;
                      case 100: statustext = "Paid"; break;
                      default: statustext = "Failed"; break;
                       
                    }
                    log.push({
                        paymentid: item.PaymentId,
                        date: moment(item.Registered).format('YYYY-MM-DD HH:mm'),
                        amount: item.Amount / 100 + ' LEI',
                        service: item.Services[0].Products[0].Name,
                        phonenumber: item.Customer.Code,
                        status: statustext
                    });
                });
                return log;
            },
            set (paymentlog) {
                return paymentlog;
            }
        },   
        LinkUrlSuccesComputed() {
          try {
            const self = this;
            return self.LinkUrlSucces + "/" + self.operation
          }
          catch {
            return false;
          }
        }

    },

    watch: {            
        
    },

    methods: {
      makePayment(account, address, city, country, amount, selectedService) {
          var initiatepayment = functions.httpsCallable('paynetinitiatepayment');
          initiatepayment({
            PhoneNumber: account.phone,
	          Email: account.email,
	          FirstName: account.firstname,
	          LastName: account.lastname,
	          Address: address,
	          City: city,
	          Country: country,
	          Amount: amount * 100,
	          InvoiceCode: selectedService               
          })
         .then((result)=> {
           const self = this;
           var paymentoutput = result.data;  
           new Promise(function(resolve) {                         
              self.operation = paymentoutput.PaymentId;
              self.Signature = paymentoutput.Signature;
              resolve(1);
            }).then(function(result) {
              self.$refs.paynetform.$el.submit();
              return result;
            });            
           });            
        },
        
    },
    created() {
        const self = this;
        this.selectedMonth = moment().add(0,'months').format("YYYY-MM-01");

        new Promise(function(resolve) {
          if (self.$store.state.account.id == '') self.$store.commit('updateAccountFromDb', auth.currentUser.phoneNumber);
          resolve(1);
        }).then(function(result) { 
          if (!self.$store.state.mypayments[0]) self.$store.commit('updatePaymentsFromDb', auth.currentUser.phoneNumber)
          return result;
        });
        
        
    }       

}


</script>